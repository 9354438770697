import React, { useEffect, useState } from 'react';
import '../styles/mainappnavbar.css';
import DarkMode from '../themes/DarkMode.tsx';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as HouseSvg } from '../assets/house.svg';
// @ts-ignore
import { ReactComponent as DocumentsSvg } from '../assets/documents.svg';
// @ts-ignore
import { ReactComponent as UniversitySvg } from '../assets/universityshowleft.svg';
// @ts-ignore
import { ReactComponent as ProfileSvg } from '../assets/user.svg';
// @ts-ignore
import { ReactComponent as QuestionSvg } from '../assets/question.svg';
// @ts-ignore
import { ReactComponent as LogoSvg } from '../assets/logo.svg';
import { Link } from 'react-router-dom';

const MainAppNavBar = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <div className='mainDiv'>
      <DarkMode />
      <div id="mainMenuDiv">
        <div id="top">
          <div>
            <Link to="/"><LogoSvg alt="LogoSvg Icon" id="logo" /></Link>
          </div>
          <div id="homePageAnimation">
            <Link to="/app/home"><HouseSvg alt="House Icon" /></Link>
            <ul>{t("mainappnavbar.home")}</ul>
          </div>
          <div id="foldersPageAnimation">
            <Link to="/app/folders"><DocumentsSvg alt="Documents Icon" /></Link>
            <ul>{t("mainappnavbar.folders")}</ul>
          </div>
          <div id="universitiesPageAnimation">
            <Link to="/app/universities"><UniversitySvg alt="UniversitySvg Icon" /></Link>
            <ul>{t("mainappnavbar.universities")}</ul>
          </div>
          <div id="profilePageAnimation">
            <Link to="/app/profile"><ProfileSvg alt="ProfileSvg Icon" /></Link>
            <ul>{t("mainappnavbar.profile")}</ul>
          </div>
        </div>

        <div id="middle">
            
        </div>

        <div id="bottom">
            <div id="homePageAnimation">
              <a href="#"><QuestionSvg alt="QuestionSvg Icon" /></a>
              <ul>{t("mainappnavbar.help")}</ul>
            </div>
        </div>
      </div>
      <div id="mainMenuDivBlockBottomMain">
        <div id='mainMenuDivBlockBottom'>
          <div id="top">
            <div id="homePageAnimation">
              <Link to="/app/home"><HouseSvg alt="House Icon" /></Link>
              <ul>{t("mainappnavbar.home")}</ul>
            </div>
            <div id="foldersPageAnimation">
              <Link to="/app/folders"><DocumentsSvg alt="Documents Icon" /></Link>
              <ul>{t("mainappnavbar.folders")}</ul>
            </div>
            <div id="universitiesPageAnimation">
              <Link to="/app/universities"><UniversitySvg alt="UniversitySvg Icon" /></Link>
              <ul>{t("mainappnavbar.universities")}</ul>
            </div>
            <div id="profilePageAnimation">
              <Link to="/app/profile"><ProfileSvg alt="ProfileSvg Icon" /></Link>
              <ul>{t("mainappnavbar.profile")}</ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAppNavBar;