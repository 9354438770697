import React, { useEffect, useState } from 'react';
import MainAppNavBar from '../MainAppNavBar.tsx';
import '../../styles/folders.css';
import { useParams } from 'react-router-dom';
// Importez correctement la fonction updateFolderName
import { createDocumentInFolder, createFolder, deleteFolder, getFolders, updateFolderName } from '../documents/createdoc.tsx';
import CreateFolderModal from '../documents/CreateFolderModal.tsx';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as DotsSvg } from '../../assets/dots.svg';
// @ts-ignore
import { ReactComponent as BusySvg } from '../../assets/busy.svg'; 
// @ts-ignore
import { ReactComponent as AddFolderSvg } from '../../assets/add-folder.svg'; 
import ModalTools from './modalFolder.tsx';
import Cookies from 'js-cookie';
import { FIREBASE_STORAGE } from '../../Firebase/firebaseConfig.tsx';
import { ref } from 'firebase/database';
import { ref as storageRef, getDownloadURL, listAll, StorageReference } from 'firebase/storage';

interface Document {
  id: string;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
  owner: string;
  text: string;
}

interface Folder {
  id: string;
  folderName: string;
  documents?: Document[];
  creationDate: string;
  owner: string;
}

const Folders = () => {
  const [t, i18n] = useTranslation("global");
  const [folders, setFolders] = useState<Folder[]>([]);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [userId, setUserId] = useState<any>(null);
  const [pdfFiles, setPdfFiles] = useState<StorageReference[][]>([]);
  const [documentCount, setDocumentCount] = useState<string>('');
  const [totalDocumentsMap, setTotalDocumentsMap] = useState<Map<string, number>>(new Map());

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
    const userIdFromCookie = Cookies.get('userId');
    setUserId(userIdFromCookie);
  }, []);

  useEffect(() => {
    fetchFolders();

    const handleFolderCreated = (event) => {
      setFolders((prevFolders) => [...prevFolders, event.detail]);
    };
    window.addEventListener('folderCreated', handleFolderCreated);
    return () => {
      window.removeEventListener('folderCreated', handleFolderCreated);
    };

  }, []);

  useEffect(() => {
    const fetchTotalDocuments = async () => {
      const updatedTotalDocumentsMap = new Map<string, number>();
  
      for (const folder of folders) {
        const totalDocuments = await calculateTotalDocuments(folder);
        const numericTotalDocuments = Number(totalDocuments);
        updatedTotalDocumentsMap.set(folder.id, numericTotalDocuments);
      }
    
      setTotalDocumentsMap(updatedTotalDocumentsMap);
    };
  
    fetchTotalDocuments();
  }, [folders]);

  const fetchFolders = async () => {
    try {
      const existingFolders: Folder[] = await getFolders() as Folder[];
      setFolders(existingFolders);
    } catch (error) {
      console.error('Erreur lors de la récupération des dossiers existants:', error.message);
    }
  };

  const countPdfFiles = async (folderId: string) => {
    try {
        const userId = Cookies.get('userId');
        const folderRef = storageRef(FIREBASE_STORAGE, `Folders/${userId}/${folderId}`);
        const result = await listAll(folderRef);
        const pdfFiles = result.items.filter((item) => item.name.toLowerCase().endsWith('.pdf'));
        return pdfFiles.length;
    } catch (error) {
        console.error('Erreur lors du comptage des fichiers PDF dans le dossier:', error);
        return 0;
    }
  };

  const calculateTotalDocuments = async (folder: Folder): Promise<number> => {
    try {
      let totalDocuments = 0;
  
      if (folder.documents) {
        totalDocuments += folder.documents.length;
      }
  
      const pdfCount = await countPdfFiles(folder.id);
      totalDocuments += pdfCount;
  
      return totalDocuments;
    } catch (error) {
      console.error('Erreur lors du calcul du nombre total de documents :', error);
      return 0;
    }
  };

  const handleCreateFolder = async (folderName) => {
    await createFolder(folderName);
    setIsCreateFolderModalOpen(false);
  };
  
  const handleOpenCreateFolderModal = () => {
    setIsCreateFolderModalOpen(true);
  };

  const handleOpenDeleteFolderModal = (folder: Folder) => {
    setSelectedFolder(folder);
    setNewFolderName(folder.folderName);
    setIsDeleteFolderModalOpen(true);
  };

  const handleCloseCreateFolderModal = () => {
    setIsCreateFolderModalOpen(false);
  };

  const handleCloseDeleteFolderModal = () => {
    setSelectedFolder(null);
    setIsDeleteFolderModalOpen(false);
  };

  const handleUpdateFolderName = async () => {
    if (selectedFolder) {
      await updateFolderName(userId, selectedFolder.id, newFolderName); 
      fetchFolders();
      setIsDeleteFolderModalOpen(false);
    }
  };

  const handleDeleteFolder = async () => {
    if (selectedFolder) {
        await deleteFolder(selectedFolder.id);
        fetchFolders();
        setIsDeleteFolderModalOpen(false);
    }
};

  const handleItemClick = (e, folder) => {
    if (e.target.id === 'svgTools') {
        handleOpenDeleteFolderModal(folder);
    } else {
        window.location.href = `/app/folder/${folder.id}`;
    }
  };

  return (
    <div className='mainAppMain'>
      <div className='mainDivAllApp'>
        <div className='mainDivApp'>
          <MainAppNavBar />
        </div>
        <div className='appDocumentFolders'>
          <div className='titleDiv'>
            <div>
              <h1>{t("folders.Folder")}</h1>
            </div>
            <div className='div-add-folder-uni'  onClick={() => handleOpenCreateFolderModal()}>
                <AddFolderSvg alt="Add Folder" />
              <button className='buttonCreateFolder'>{t("folders.createFolder")}</button>
            </div>
          </div>
          <div className='fetchFoldersDiv'>
            {folders.length === 0 ? (
              <div className='noFoldersDiv'>
                <div className='noFolderDivSvg'>
                  <BusySvg alt="BusySvg Icon" id="svgBusy" />
                </div>
                <button className='noFolderButton' onClick={handleOpenCreateFolderModal}>{t("folders.createFirstFolder")}</button>
              </div>
            ) : (
              folders.map(folder => (
                <div key={folder.id} className='folderItem' onClick={(e) => handleItemClick(e, folder)}>
                  <div className='folderInformations'>
                    <h2>{folder.folderName}</h2>
                    <h3>
                      {totalDocumentsMap.get(folder.id) === 0
                        ? t("folders.nofolder")
                        : totalDocumentsMap.get(folder.id) === 1
                        ? "1" + t("folders.document")
                        : `${totalDocumentsMap.get(folder.id)}` + t("folders.documents")}
                    </h3>
                  </div>
                  <div className='folderTools'>
                    <div></div>
                    <div onClick={() => handleOpenDeleteFolderModal(folder)}>
                      <DotsSvg alt="ProfileSvg Icon" id="svgTools" />
                    </div>
                  </div>
                  
                  {folder.documents && folder.documents.length === 0 && (
                    <div className='noDocumentsDiv'>
                      Error?
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        onClose={handleCloseCreateFolderModal}
        onCreateFolder={handleCreateFolder}
      />
      <ModalTools
        isOpen={isDeleteFolderModalOpen}
        onClose={handleCloseDeleteFolderModal}
        onDeleteFolder={handleDeleteFolder}
        folderName={selectedFolder ? selectedFolder.folderName : ''}
        newFolderName={newFolderName}
        setNewFolderName={setNewFolderName}
        userId={userId}
        folderId={selectedFolder ? selectedFolder.id : ''}
      />
    </div>
  );
}

export default Folders;