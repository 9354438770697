import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

interface DeleteDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteDocument: (documentId: string) => Promise<void>;
  onUpdateDocumentName: (documentId: string, newDocumentName: string) => Promise<void>;
  documentId: string;
  currentDocumentName: string;
}

const DeleteDocumentModal: React.FC<DeleteDocumentModalProps> = ({
  isOpen,
  onClose,
  onDeleteDocument,
  onUpdateDocumentName,
  documentId,
  currentDocumentName,
}) => {
  const [t, i18n] = useTranslation("global");
  const [newDocumentName, setNewDocumentName] = useState(currentDocumentName);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  const handleDeleteDocument = async () => {
    try {
      await onDeleteDocument(documentId);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la suppression du document:', error.message);
    }
  };

  const handleSaveDocumentName = async () => {
    try {
      await onUpdateDocumentName(documentId, newDocumentName.trim());
      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du nom du document:', error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete Document"
      style={{
        content: {
          width: '350px',
          height: '250px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <div className='modalMainDivFolderDelete'>
        <div className='mainButtonContainerFolderDelete'>
          <div>
            <h1 style={{textAlign: 'left'}}>{t("modalDeleteFolder.updatefolder")}</h1>
            <input
              type="text"
              placeholder={t("modalDeleteFolder.modifyFolder")}
              value={newDocumentName}
              className='inputs-folder'
              onChange={(e) => setNewDocumentName(e.target.value)}
            />
          </div>
          <div className='bottom-footer-div-folder-modal'>
            <div className='buttonContainerFolderDelete' style={{textAlign: 'center', marginTop: '20px', fontSize: '20px'}}>
              <button onClick={handleDeleteDocument}>{t("modalDeleteFolder.delete")}</button>
            </div>
            <button onClick={handleSaveDocumentName} className='save-button-folder'>{t("modalDeleteFolder.save")}</button>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;