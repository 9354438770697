import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FIREBASE_AUTH, FIREBASE_DB } from '../Firebase/firebaseConfig.tsx';
import { ref, set, serverTimestamp } from 'firebase/database';
import { useTranslation } from 'react-i18next';
import '../styles/signUp.css'

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [fullname, setFullName] = useState('');
  const [status, setStatus] = useState('');
  const [language, setLanguage] = useState(navigator.language);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation("global");

  const { login } = useAuth();
  const navigate = useNavigate();

  const signUp = async () => {
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(FIREBASE_AUTH, email, password);
      const user = userCredential.user;

      console.log('Compte créé avec succès', user);
      Cookies.set('userId', user.uid, { expires: 14 });
      Cookies.set('email', user.email, { expires: 14 });
      saveUserData(user.uid);
      createUserFolder(user.uid);

      navigate('/app/home');
    } catch (error) {
      console.error('Erreur de création de compte:', error.message);

      if (error.code) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            setError(t("signUp.alreadyuse"));
            break;
          case 'auth/invalid-email':
            setError(t("signUp.invalid"));
            break;
          default:
            setError(t("signUp.errorunex"));
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 50;
    let result = '';
    for (let i = 0; i < idLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const userToken = generateRandomId();

  const saveUserData = async (userid) => {
    try {
      const userData = {
        email,
        fullname,
        username,
        status,
        userToken,
        userid,
        createdAt: new Date().toISOString(),
        accountType: 'default',
        accountAdmin: false,
        language,
      };
  
      const userRef = ref(FIREBASE_DB, `/Users/${userid}`);
      await set(userRef, userData);
  
      console.log('User information with image URL saved successfully!');
    } catch (error) {
      console.error('Error saving user information with image URL:', error);
    }
  };

  const createUserFolder = async (userid) => {
    try {
      const userFolder = {
        email,
        username,
        userid,
        folder: "",
        status,
        createdAt: new Date().toISOString(),
        accountType: 'default',
        accountAdmin: false,
        language,
      };
  
      const userRef = ref(FIREBASE_DB, `/Folders/${userid}`);
      await set(userRef, userFolder);
  
      console.log('User folder created!');
    } catch (error) {
      console.error('Error saving user information with image URL:', error);
    }
  };

  const handleRegistration = () => {
    signUp();
  };

  return (
    <div className="h-screen bg-gray-100 flex justify-center">
      <div className="py-6 px-7 h-80 mt-20 bg-white rounded shadow-xl" id="box">
      <div className="mb-6">
          <label className="block text-gray-800 font-bold">{t("signUp.username")}</label>
          <input
            type="text"
            name="username"
            id="username"
            placeholder={t("signUp.uusername")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-800 font-bold">{t("signUp.fullname")}</label>
          <input
            type="text"
            name="fullname"
            id="fullname"
            placeholder={t("signUp.ffullname")}
            value={fullname}
            onChange={(e) => setFullName(e.target.value)}
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-800 font-bold">{t("signUp.Email")}</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder={t("signUp.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-800 font-bold">{t("signUp.Password")}</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder={t("signUp.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-800 font-bold">{t("signUp.Status")}</label>
          <select
            name="status"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600"
          >
            <option value="student">{t("signUp.Student")}</option>
            <option value="professional">{t("signUp.Professional")}</option>
            <option value="teacher">{t("signUp.Teacher")}</option>
          </select>
        </div>

        <button
          className="cursor-pointer py-2 px-4 block mt-6 bg-indigo-500 text-white font-bold w-full text-center rounded"
          onClick={handleRegistration}
        >
          {t("signUp.signUp")}
        </button>
        {error && <h1 style={{ color: 'red', marginTop: 10 }}>{error}</h1>}
      </div>
    </div>
  );
};

export default SignUp;