import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const CreateFolderModal = ({ isOpen, onClose, onCreateFolder }) => {
  const [folderName, setFolderName] = useState('');
  const [t, i18n] = useTranslation("global");

  const handleCreateFolder = () => {
    onCreateFolder(folderName);
    setFolderName('');
    onClose();
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create Folder"
      style={{
        content: {
          width: '300px',
          height: '200px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <div className='modalMainDiv'>
        <div className='mainButtonContainer'>
          <div className='buttonContainer'>
            <div>
              <h2 className='creationOrgaUnih1'>{t("university.createFolder")}</h2>
              <input
                type='text'
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                style={{marginTop: '10px', padding: '5px', border: '1px solid var(--border-color)', color: 'var(--textColorInputProfile', backgroundColor: 'var(--main-background-document)', borderRadius: '5px'}}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <button onClick={handleCreateFolder}>Create</button>
              <button onClick={onClose} style={{marginLeft: '10px'}}>Cancel</button>

            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateFolderModal;