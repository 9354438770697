import { initializeApp } from 'firebase/app';
import {getDatabase} from 'firebase/database';
import { browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase

//
//This is secret informations, do not use anywhere else
//

const firebaseConfig = {
    apiKey: "AIzaSyCdC-TwUaSidxgzEDj_TJBZ46r3hDGoFEI",
    authDomain: "wolly-8780f.firebaseapp.com",
    databaseURL: "https://wolly-8780f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "wolly-8780f",
    storageBucket: "wolly-8780f.appspot.com",
    messagingSenderId: "635330192277",
    appId: "1:635330192277:web:2ac0824dad8c73efab0c9c",
    measurementId: "G-34XPTWZZD6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Configure la persistance de l'authentification
setPersistence(auth, browserSessionPersistence);

export const FIREBASE_APP = initializeApp(firebaseConfig); //Export app
export const FIREBASE_DB = getDatabase(FIREBASE_APP); //Export the database for later
export const FIREBASE_AUTH = getAuth(FIREBASE_APP); //Export for auth - login menu
export const FIREBASE_STORAGE = getStorage(FIREBASE_APP);