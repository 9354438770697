import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals.js';
import { AuthProvider } from './hooks/AuthContext.js';
import { I18nextProvider } from 'react-i18next';
import global_en from './translations/en/global.json';
import global_fr from './translations/fr/global.json';
import global_pl from './translations/pl/global.json';
import global_es from './translations/es/global.json';
import global_de from './translations/de/global.json';
import i18next from "i18next";

i18next.init({
  interpolation: {escapeValue: false},
  lng: "en",
  resources: {
    en: {
      global: global_en
    },
    fr: {
      global: global_fr
    },
    gb: {
      global: global_en
    },
    pl: {
      global: global_pl
    },
    de: {
      global: global_de
    },
    es: {
      global: global_es
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
