import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/createfoldermodal.css';
import { useTranslation } from 'react-i18next';

const CreateFolderModal = ({ isOpen, onClose, onCreateFolder }) => {
  const [t, i18n] = useTranslation("global");
  const [folderName, setFolderName] = useState('');

  const handleCreateFolder = () => {
    onCreateFolder(folderName);
    setFolderName('');
    onClose();
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  return (
        <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Créer un dossier"
        style={{
            content: {
                width: '400px',
                height: '200px',
                margin: 'auto',
                backgroundColor: 'var(--main-background-document)',
                border: '0px',
                borderRadius: '10px',
                color: 'var(--textColor)',
                maxWidth: '80%'
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
        }}
        >
        <div className='modalMainDiv'>
            <h2 className='h2'>{t("modalCreateFolder.mainText")}</h2>
            <div className='labelInputContainer'>
                <label>{t("modalCreateFolder.name")}</label>
                <input
                    type="text"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    style={{ display: 'block', width: '100%', backgroundColor: 'transparent', border: '2px solid var(--border-color)', borderRadius: '5px', padding: '5px', marginTop: '5px' }}
                />
            </div>
            <div className='mainButtonContainer'>
                <div className='buttonContainer'>
                    <button onClick={onClose}>{t("modalCreateFolder.cancel")}</button>
                </div>
                <div className='buttonContainer'>
                    <button onClick={handleCreateFolder}>{t("modalCreateFolder.create")}</button>
                </div>
            </div>
        </div>
        
        </Modal>
  );
};

export default CreateFolderModal;