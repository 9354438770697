import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, get, DataSnapshot, query, orderByChild, equalTo, push, set, remove, update } from 'firebase/database';
import MainAppNavBar from '../MainAppNavBar.tsx';
import '../../styles/folderDoc.css';
import Cookies from 'js-cookie';
import CreateFolderModal from './CreateFolderModal.tsx';
import DeleteFolderModal from './DeleteFolderModal.tsx';

// @ts-ignore
import { ReactComponent as DotsSvg } from '../../assets/dots.svg';
// @ts-ignore
import { ReactComponent as LeftArrow } from '../../assets/leftarrow.svg';
// @ts-ignore
import { ReactComponent as BusySvg } from '../../assets/busy.svg'; 
// @ts-ignore
import { ReactComponent as AddFolderSvg } from '../../assets/add-folder.svg'; 

import { FIREBASE_DB } from '../../Firebase/firebaseConfig.tsx';
import { useTranslation } from 'react-i18next';
import handleUpdateFolderName from "./DeleteFolderModal.tsx"

interface Document {
  id: string;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
  owner: string;
  text: string;
}

interface Folder {
  id: string;
  folderName: string;
  documents?: Document[];
  creationDate: string;
  owner: string;
  note: number;
}



const University = () => {
  const userIdFromCookie = Cookies.get('userId');
  const [t, i18n] = useTranslation("global");
  const { organisationId } = useParams();
  const [universityName, setUniversityName] = useState<string | null>(null);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder | null>(null);
  const [folderToDeleteId, setFolderToDeleteId] = useState<string | null>(null);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    fetchUniversityName();
    fetchUniversityFolders();
  }, [organisationId]);

  const fetchUniversityName = async () => {
    try {
      if (!organisationId) {
        return;
      }
  
      const universitiesRef = ref(FIREBASE_DB, '/Universities');
      const universitiesQuery = query(
        universitiesRef,
        orderByChild('uniId'),
        equalTo(organisationId)
      );
  
      const universitiesSnapshot: DataSnapshot = await get(universitiesQuery);
  
      if (universitiesSnapshot.exists()) {
        const universityData: Record<string, { name: string }> = universitiesSnapshot.val();
        const firstUniversityName = Object.values(universityData)[0]?.name || null;
        setUniversityName(firstUniversityName);
      } else {
        setUniversityName(null);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du nom de l\'université:', error.message);
    }
  };
  

  const fetchUniversityFolders = async () => {
    try {
      if (!organisationId) {
        return;
      }

      const foldersRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}`);
      const foldersSnapshot: DataSnapshot = await get(foldersRef);

      if (foldersSnapshot.exists()) {
        const foldersData: Record<string, Folder> = foldersSnapshot.val();
        const foldersArray: Folder[] = Object.values(foldersData);
        setFolders(foldersArray);
      } else {
        setFolders([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des dossiers de l\'université:', error.message);
    }
  };

  const handleCreateFolderClick = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateFolder = async (folderName) => {
    try {
      const foldersRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}`);
      const newFolderRef = push(foldersRef);
      const newFolderId = newFolderRef.key;

      if (newFolderId) {
        const newFolder: Folder = {
          id: newFolderId,
          folderName: folderName,
          creationDate: new Date().toISOString(),
          owner: userIdFromCookie,
          note: 0,
        };

        await set(newFolderRef, newFolder);
        setFolders((prevFolders) => [...prevFolders, newFolder]);
      }

      setIsCreateModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la création du dossier:', error.message);
    }
  };
  
  const handleOpenDeleteModal = (folder: Folder) => {
    setSelectedFolder(folder);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteFolder = async () => {
    try {
      if (selectedFolder) {
        const folderRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${selectedFolder.id}`);
        await remove(folderRef);
        setFolders((prevFolders) => prevFolders.filter(folder => folder.id !== selectedFolder.id));
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du dossier:', error.message);
    }
  };

  const handleItemClick = (e, folder) => {
    if (e.target.id === 'svgTools') {
        setIsDeleteModalOpen(true);
        setFolderToDeleteId(folder.id);
    } else {
      window.location.href = `/app/home/${organisationId}/folder/${folder.id}`;
    }
  };

  const handleItemClickBack = () => {
    window.location.href = `/app/universities`;
  };

  const handleUpdateFolderName = async (folderId: string, newFolderName: string) => {
    if (!newFolderName.trim()) {
      return;
    }
    try {
      const folderRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}`);
      await update(folderRef, { folderName: newFolderName.trim() });
      setIsDeleteModalOpen(false); 
    } catch (error) {
      console.error('Erreur lors de la mise à jour du nom du dossier:', error.message);
    }
  };

  return (
    <div className='mainAppMain'>
      <div className='mainDivAllApp'>
        <div className='mainDivApp'>
          <MainAppNavBar />
        </div>
        <div className='appDocumentFolders'>
          <div className='titleDiv'>
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <LeftArrow alt="LeftArrow Icon" style={{ marginTop: '10px', cursor: 'pointer' }} onClick={handleItemClickBack} />
              <h1>{universityName || 'Loading...'}</h1>
            </div>
            <div className='div-add-folder-uni'  onClick={() => handleCreateFolderClick()}>
                <AddFolderSvg alt="Add Folder" />
              <button className='buttonCreateFolder'>{t("university.createUni")}</button>
            </div>
          </div>
          <div className='fetchFoldersDivUni'>
            {folders.length === 0 ? (
              <div className='noFoldersDiv'>
                <div className='noFolderDivSvg'>
                  <BusySvg alt="BusySvg Icon" id="svgBusy" />
                </div>
                <button className='noFolderButton' onClick={handleCreateFolderClick}>{t("university.createFirst")}</button>
              </div>
            ) : (
              folders.map((folder) => (
                <div key={folder.id} className='folderItem' onClick={(e) => handleItemClick(e, folder)}>
                  <div className='folderInformations'>
                    <h3>{folder.folderName}</h3>
                  </div>
                  <div className='folderTools'>
                    <div></div>
                    {folder.owner === userIdFromCookie && (
                      <div onClick={() => handleOpenDeleteModal(folder)}>
                        <DotsSvg alt="DotsSvg Icon" id="svgTools" onClick={() => handleOpenDeleteModal(folder)}/>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <CreateFolderModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onCreateFolder={handleCreateFolder}
      />
      <DeleteFolderModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDeleteFolder={handleDeleteFolder}
        onUpdateFolderName={handleUpdateFolderName} 
        currentFolderName={selectedFolder?.folderName || ''}
        folderId={selectedFolder?.id || ''}
      />
    </div>
  );
};

export default University;