import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

interface CreateDocumentModalProps {
    isOpen: boolean;
    onclose: () => void;
    onCreateDocument: (title: string, text: string) => void;
  }

const CreateDocumentModal: React.FC<CreateDocumentModalProps> = ({ isOpen, onclose, onCreateDocument }) => {
  const [title, setTitle] = useState('');
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTitle('');
    }
  }, [isOpen]);

  const handleCreateDocument = () => {
  const text = 'votreValeurDeTexte';
  onCreateDocument(title, text);
  setTitle('');
  onclose();
};

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onclose}
      contentLabel="Create Document"
      style={{
        content: {
          width: '400px',
          height: '170px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          padding: '20px',
          color: 'var(--textColor)',
          maxWidth: '80%'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <div className={`modalCreateDocu ${isOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className='modal-content' onClick={(e) => e.stopPropagation()} style={{textAlign: 'center'}}>
          <h2 style={{fontWeight: 'bold'}}>{t("createDocumentUni.createTitle")}</h2>
          <div className='modal-form'>
            <div>
              <input type='text'
              style={{marginTop: '10px', padding: '5px', border: '1px solid var(--border-color)', color: 'var(--textColorInputProfile', backgroundColor: 'var(--main-background-document)', borderRadius: '5px'}}
              value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div style={{marginTop: '10px'}}>
              <button onClick={handleCreateDocument}>{t("createDocumentUni.create")}</button>
              <button onClick={onclose} style={{marginLeft: '10px'}}>{t("createDocumentUni.cancel")}</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateDocumentModal;