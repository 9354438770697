import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation("global");
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language
  );

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage && storedLanguage !== selectedLanguage) {
      i18n.changeLanguage(storedLanguage);
      setSelectedLanguage(storedLanguage);
    }
  }, [i18n, selectedLanguage]);

  return (
    <div>
      <label>
        <select
          className="selectionMenuProfile"
          value={selectedLanguage}
          onChange={(e) => handleChangeLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="fr">French</option>
        </select>
      </label>
    </div>
  );
};

export default LanguageSelector;