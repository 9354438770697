import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { updateFolderName } from '../documents/createdoc.tsx';

interface ModalToolsProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteFolder: () => Promise<void>;
  folderName: string;
  setNewFolderName: React.Dispatch<React.SetStateAction<string>>;
  userId: any;
  folderId: string;
  newFolderName: string;
}

const ModalTools: React.FC<ModalToolsProps> = ({ isOpen, onClose, onDeleteFolder, folderName, userId, folderId }) => {
  const [t, i18n] = useTranslation("global");
  const [newFolderName, setNewFolderName] = useState(folderName);

  const handleDeleteFolder = () => {
    onDeleteFolder();
    onClose();
  };

  const handleUpdateFolder = () => {
    updateFolderName(userId, folderId, newFolderName)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error('Error updating folder name in Firebase:', error);
      });
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete Folder in Folder"
      style={{
        content: {
          width: '350px',
          height: '250px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }
      }}
    >
      <div className='modalMainDivFolderDelete'>
        <div className='mainButtonContainerFolderDelete'>
          <div>
            <h1 style={{textAlign: 'left'}}>{t("modalDeleteFolder.updatefolder")}</h1>
            <input
              type="text"
              placeholder={t("modalDeleteFolder.modifyFolder")}
              value={newFolderName}
              className='inputs-folder'
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </div>
          <div className='bottom-footer-div-folder-modal'>
            <div className='buttonContainerFolderDelete' style={{textAlign: 'center', marginTop: '20px', fontSize: '20px'}}>
              <button onClick={handleDeleteFolder}>{t("modalDeleteFolder.delete")}</button>
            </div>
            <button onClick={handleUpdateFolder} className='save-button-folder'>{t("modalDeleteFolder.save")}</button>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTools;