import React, { useEffect, useState } from 'react';
import '../../styles/profileModal.css';
import DarkModeButton from '../../themes/DarkModeButton.tsx';
import LanguageSelector from '../../themes/LanguageSelector.tsx';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import { ReactComponent as LeftArrow } from '../../assets/leftarrow.svg';
// @ts-ignore
import { ReactComponent as LogOutArrow } from '../../assets/logout.svg';
// @ts-ignore
import { ReactComponent as DeleteSvg } from '../../assets/delete.svg';

const ProfileModal = ({ onClose }) => {
  const [closing, setClosing] = useState(false);
  const [t, i18n] = useTranslation("global");
  const { logout, deleteAccount } = useAuth(); 
  const navigate = useNavigate();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      closeModal();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error.message);
    }
  };

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm('Are you sure you want to delete your account? This action is irreversible.');

    if (confirmed) {
      try {
        await deleteAccount();
        closeModal();
        navigate('/login');
      } catch (error) {
        console.error('Error during account deletion:', error.message);
      }
    }
  };

  const closeModal = () => {
    setClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div className={`modalOverlay${closing ? ' closing' : ''}`}>
      <div className={`modal${closing ? ' closing' : ''}`}>
        <div className='divProfileHeader'>
          <LeftArrow
            alt='LeftArrow Icon'
            style={{ cursor: 'pointer', marginTop: '5px' }}
            onClick={closeModal}
          />
          <h1 className='h1TextProfileSettings'>{t("profile.settings")}</h1>
        </div>
        <div className='changeSettingsPageProfile'>
          <div className='changeSettingsPageProfileDiv'>
            <h1>{t("profile.appearance")}</h1>
            <DarkModeButton />
          </div>
          <div className='changeSettingsPageProfileDiv'>
            <h1>{t('profile.language')}</h1>
            <LanguageSelector />
          </div>
        </div>
        <div className='changeSettingsPageProfileOthers'>
          <div onClick={handleLogout} className='logOutdiv'>
            <LogOutArrow alt='LogOutArrow Icon' />
            <button onClick={handleLogout} className='marginTextLeft'>{t("profile.logout")}</button>
          </div>
        </div>
        <div className='changeSettingsPageProfileOthers'>
          <div onClick={handleDeleteAccount} className='deleteAccountDiv'>
            <DeleteSvg alt='DeleteSvg Icon' />
            <button className='marginTextLeft'>{t("profile.deleteaccount")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;