import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import { ref, set, get } from 'firebase/database';
import { FIREBASE_DB } from '../Firebase/firebaseConfig.tsx';
import Cookies from 'js-cookie';
import DarkMode from '../themes/DarkMode.tsx';
import '../styles/texteditor.css';
import jsPDF from 'jspdf';

// @ts-ignore
import { ReactComponent as PrintSvg } from '../assets/print.svg';

Quill.register('modules/imageResize', ImageResize);

const userIdFromCookie = Cookies.get('userId');
const userId = userIdFromCookie;

const TextEditorQuill = ({ folderId, documentId, organisationId }) => {
  const [text, setText] = useState('');
  const quillRef = useRef<ReactQuill | null>(null);
  const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color');

  useEffect(() => {
    let pathToSavedContent = '';
    if(organisationId === undefined){
      pathToSavedContent = `Folders/${userId}/folder/${folderId}/Documents/${documentId}`;
    } else{
      pathToSavedContent = `UniversityFolders/${organisationId}/${folderId}/documents/${documentId}`;
    }
    
    Promise.all([
      get(ref(FIREBASE_DB, `${pathToSavedContent}/htmlContent`)),
      get(ref(FIREBASE_DB, `${pathToSavedContent}/ops`)),
    ]).then(([htmlContentSnapshot, opsSnapshot]) => {
      const savedContent = htmlContentSnapshot.val();
      const ops = opsSnapshot.val();
  
      if (savedContent) {
        setText(savedContent);
  
        if (quillRef.current) {
          const quill = quillRef.current.getEditor();
  
          quill.setContents(ops);
          
          setTimeout(() => {
            const editor = quill.root;
  
            const images = editor.querySelectorAll('.ql-editor img');
  
            images.forEach((imgElement) => {
              if (imgElement instanceof HTMLImageElement) {
                imgElement.style.width = '300px';
                imgElement.style.height= '300px';
              }
            });
  
            quill.format('imageResize', true);
          }, 0); 
        }
      }
    });
  }, [userId, folderId, documentId]);

  const saveContentToFirebase = (content) => {
    try {
      let pathToSavedContent = '';
      if(organisationId === undefined){
        pathToSavedContent = `Folders/${userId}/folder/${folderId}/Documents/${documentId}`;
      } else{
        pathToSavedContent = `UniversityFolders/${organisationId}/${folderId}/documents/${documentId}`;
      }
      const htmlContent = quillRef.current?.getEditor().root.innerHTML;
      set(ref(FIREBASE_DB, `${pathToSavedContent}/htmlContent`), htmlContent);

      set(ref(FIREBASE_DB, `${pathToSavedContent}/ops`), content.ops);
    } catch (error) {
      console.error('Error in saveContentToFirebase:', error);
    }
  };

  const handleTextChange = (newText, _, source) => {
    if (quillRef.current && source === 'user') {
      const quill = quillRef.current.getEditor();

      // Sauvegarde à chaque modification
      const content = quill.getContents();
      saveContentToFirebase(content);
    }

    setText(newText);
  };

  /*
  Function to export pdf, not really working well but still possibile to use it
  const exportToPDF = () => {
    const quillContent = quillRef.current?.getEditor().root.innerHTML;
  
    if (typeof quillContent === 'string') {
      // Création d'un nouveau document PDF
      const pdf = new jsPDF();
      pdf.html(quillContent, {
        callback: () => {
          // Une fois que le contenu HTML est ajouté au PDF, téléchargez le fichier PDF généré
          pdf.save('document.pdf');
        }
      });
    } else {
      console.error('Quill content is not valid.');
    }
  };
  */

  const printDocument = () => {
    window.print();
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
      [{ 'align': [] }],
      [{ 'background': [] }]
    ],
    imageResize: {},
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'color', 'background', 'align',
  ];


  return (
    <div className='menu-editor-quill-navbar'>
      <div className='print-document-div-main'>
        <PrintSvg onClick={printDocument} />
      </div>
      <ReactQuill
        ref={quillRef}
        onChange={handleTextChange}
        modules={modules}
        formats={formats}
        className='text-editor'
        value={text}
      />
    </div>
  );
};

export default TextEditorQuill;