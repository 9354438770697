import React, { useEffect, useState } from 'react';
import MainAppNavBar from '../MainAppNavBar.tsx';
import { useTranslation } from 'react-i18next';
import ModalTools from './ModalTools.tsx';
import { ref, get, DataSnapshot, query, orderByChild, startAt, endAt, set, Query, onValue } from 'firebase/database';
import { FIREBASE_DB } from '../../Firebase/firebaseConfig.tsx';
import Cookies from 'js-cookie';
import '../../styles/universities.css';
import ModalDeleteOrga from './ModalDeleteOrga.tsx';

// @ts-ignore
import { ReactComponent as UniversitySvg } from '../../assets/universityshow.svg';
// @ts-ignore
import { ReactComponent as DotsSvg } from '../../assets/dots.svg';
// @ts-ignore
import { ReactComponent as CompanySvg } from '../../assets/company.svg';
// @ts-ignore
import { ReactComponent as SearchSvg } from '../../assets/search.svg';
// @ts-ignore
import { ReactComponent as AddFolderSvg } from '../../assets/add-square.svg';
// @ts-ignore
import { ReactComponent as AddElliSvg } from '../../assets/add-ellipse.svg';
// @ts-ignore
import { ReactComponent as OkSvg } from '../../assets/ok-svg.svg';


interface University {
  id: string;
  name: string;
  type: string;
  visibility: string;
}

const Universities = () => {
  const [t, i18n] = useTranslation("global");
  const [universities, setUniversities] = useState<University[]>([]);
  const [mySelectedUniversities, setMySelectedUniversities] = useState<string[]>([]);
  const [isCreateUniversityModalOpen, setIsCreateUniversityModalOpen] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState<University | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDeleteUniversityModalOpen, setIsDeleteUniversityModalOpen] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const userId = Cookies.get('userId');
            const userRef = ref(FIREBASE_DB, `/Users/${userId}`);
            const userSnapshot: DataSnapshot = await get(userRef);
            const userData = userSnapshot.val();

            if (userData && userData.accountAdmin) {
                setUserAdmin(true);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données de l\'utilisateur:', error.message);
        }
    };

    fetchUserData();
}, []);

  useEffect(() => {
    fetchUniversities();
    fetchUserSelectedUniversities();
  }, [searchTerm]);

  const fetchUniversities = async () => {
    try {
      const universitiesRef = ref(FIREBASE_DB, '/Universities');
      let currentQuery: Query;

      if (searchTerm) {
        const searchTermLowercase = searchTerm.toLowerCase();
        currentQuery = query(
          universitiesRef,
          orderByChild('name_lowercase'),
          startAt(searchTermLowercase),
          endAt(searchTermLowercase + '\uf8ff')
        );
      } else {
        currentQuery = universitiesRef;
      }

      const snapshot: DataSnapshot = await get(currentQuery);

      if (snapshot.exists()) {
        const universitiesData: Record<string, University> = snapshot.val();
        const universitiesArray: University[] = Object.values(universitiesData);

        setUniversities(universitiesArray);
      } else {
        setUniversities([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des universités:', error.message);
    }
  };

  const fetchUserSelectedUniversities = async () => {
    try {
      const userId = Cookies.get('userId');
      const userRef = ref(FIREBASE_DB, `/Users/${userId}`);
      const userSnapshot: DataSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (userData.followOrga) {
        const userSelectedUniversities = Object.keys(userData.followOrga);
        setMySelectedUniversities(userSelectedUniversities);
      } else {
        setMySelectedUniversities([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des universités suivies par l\'utilisateur:', error.message);
    }
  };

  const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 15;
    let result = '';
    for (let i = 0; i < idLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleCreateUniversity = async (name, type, visibility) => {
    try {
      const idUni = generateRandomId()
      const universityData = {
        name: name,
        type: type,
        visibility: visibility,
        id: idUni,
        followers: 0,
        description: '',
        totalOfFiles: 0,
        score: 0,
        uniId: idUni,
        name_lowercase: name.toLowerCase(),
        folders: '',
      };
      const universityChildRef = ref(FIREBASE_DB, `/Universities/${universityData.id}`);

      await set(universityChildRef, universityData);


      fetchUniversities();

      setIsCreateUniversityModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la création de l\'université:', error.message);
    }
  };


  const handleOpenCreateUniversityModal = () => {
    setIsCreateUniversityModalOpen(true);
  };

  const handleCloseCreateUniversityModal = () => {
    setIsCreateUniversityModalOpen(false);
  };

  const handleDeleteUniversityModal = (university: University) => {
    setSelectedUniversity(university);
    setIsDeleteUniversityModalOpen(true);
  };

  const handleCloseDeleteUniversityModal = () => {
    setIsDeleteUniversityModalOpen(false);
  };

  const handleDeleteUniversityConfirm = async () => {
    try {
      const userId = Cookies.get('userId');
      const userRef = ref(FIREBASE_DB, `/Users/${userId}`);
      const userSnapshot: DataSnapshot = await get(userRef);
      const userData = userSnapshot.val();
  
      if (userData.followOrga && selectedUniversity) {
        delete userData.followOrga[selectedUniversity.id];

        setMySelectedUniversities(prevSelected => prevSelected.filter(id => id !== selectedUniversity.id));
  
        await set(userRef, userData);
      }
  
      handleCloseDeleteUniversityModal();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'université:', error.message);
    }
  };

  const handleItemClick = (e, university) => {
    if (e.target.id === 'svgTools') {
      handleDeleteUniversityModal(university);
    } else {
      window.location.href = `home/${university.uniId}`;
    }
  };

  const handleFollowUniversity = async (universityId) => {
    try {
      const userId = Cookies.get('userId');
      const userRef = ref(FIREBASE_DB, `/Users/${userId}`);
      const userSnapshot: DataSnapshot = await get(userRef);
      const userData = userSnapshot.val();
  
      let followLimit = 0;
      if (userData.accountType === 'default') {
        followLimit = 1;
      } else if (userData.accountType === 'premium') {
        followLimit = 2;
      } else if (userData.accountType === 'schoolL') {
        followLimit = 3;
      }
  
      if (!userData.followOrga || Object.keys(userData.followOrga).length < followLimit) {
        if (!userData.followOrga) {
          userData.followOrga = {};
        }
        userData.followOrga[universityId] = true;
  
        await set(userRef, userData);
  
        fetchUniversities();
        fetchUserSelectedUniversities();
      } else {
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 4000);
      }
    } catch (error) {
      console.error('Erreur lors du suivi de l\'université:', error.message);
    }
  };

  const isUniversityFollowed = (universityId) => {
    return mySelectedUniversities.includes(universityId);
  };

  return (
    <div className='mainAppMain'>
      <div className='mainDivAllApp'>
        <div className='mainDivApp'>
          <MainAppNavBar />
        </div>
        <div className='appDocumentFolders'>
          <div className='titleDiv'>
            <div>
              <h1>{t("universities.universitiesh1")}</h1>
            </div>
            <div className='search-main-div-universities'>
              <SearchSvg alt="Search University" className="searchIconSvg"/>
              <input
                type="text"
                placeholder={t("universities.nameoftheorganization")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className='inputSearchTerm'
              />
            </div>
            <div className='div-add-folder-uni-uni' onClick={handleOpenCreateUniversityModal}>
              <AddFolderSvg alt="Add Folder" />
              <button className='buttonCreateFolder'>{t("universities.createanorganization")}</button>
            </div>
          </div>
          <div className='mySelectedUniversities'>
              <h1 className='findOrgaH1'>{t("universities.myselection")}</h1>
              {mySelectedUniversities.map(universityId => {
                const selectedUniversity = universities.find(u => u.id === universityId);
                if(selectedUniversity) {
                  return (
                    <div key={universityId} className='selectedUniversityItem' onClick={(e) => handleItemClick(e, selectedUniversity)}>
                      <div className='orgaItemLeft'>
                        <UniversitySvg alt="UniversitySvg Icon" id="SvgTypetwo"/>
                        <h2>{selectedUniversity.name}</h2>
                      </div>
                        <div onClick={() => selectedUniversity && handleDeleteUniversityModal(selectedUniversity)}>
                            <DotsSvg alt="ProfileSvg Icon" id="svgTools" />
                        </div>
                    </div>
                  );
                }
              })}
            </div>
          <div>
            <h1 className='findOrgaH1'>{t("universities.findanewone")}</h1>
          </div>
          <div className='fetchUniversitiesDiv'>
            {universities.map(university => (
              <div key={university.id} className='universityItem'>
                <div className='svgContainer'>
                  {university.type === 'school' ? (
                    <UniversitySvg alt="UniversitySvg Icon" id="SvgType" />
                  ) : (
                    <CompanySvg alt="CompanySvg Icon" id="SvgType" />
                  )}
                </div>
                <h2>{university.name}</h2>
                <div className='divIconsSvgType'>
                  <div className='followContainer'>
                    {isUniversityFollowed(university.id) ? (
                      <OkSvg className='pFollowed' />
                    ) : (
                      <AddElliSvg onClick={() => handleFollowUniversity(university.id)} className='buttonFollowUni' />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalTools
        isOpen={isCreateUniversityModalOpen}
        onClose={handleCloseCreateUniversityModal}
        onCreateUniversity={handleCreateUniversity}
      />
      <ModalDeleteOrga
        isOpen={isDeleteUniversityModalOpen}
        onClose={handleCloseDeleteUniversityModal}
        onConfirm={handleDeleteUniversityConfirm}
      />
      {showErrorMessage && (
        <div className={`max-w-xs bg-red-500 text-sm text-white rounded-xl shadow-lg ${showErrorMessage ? 'fade-in' : 'fade-out'}`} role="alert">
          <div className="flex p-4">
            {t("universities.maxUni")}
          </div>
        </div>
      )}
    </div>
  );
}

export default Universities;