import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Home from './pages/Home.tsx';
import Login from './pages/Login.tsx';
import MainApp from './app/MainApp.tsx';
import Cookies from 'js-cookie';
import SignUp from './pages/SignUp.tsx';
import Folders from './app/folders/Folders.tsx';
import Folder from './app/folders/Folder.tsx';
import Document from './app/documents/Document.tsx';
import NotFound from './pages/NotFound.tsx';
import Universities from './app/universities/Universities.tsx';
import University from './app/universities/University.tsx';
import FolderUniversity from './app/universities/Folders/FolderUniversity.tsx';
import Profile from './app/profile/Profile.tsx';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';

const PrivateRoute = () => {
  const userIdFromCookie = Cookies.get('userId');
  const isUserLoggedIn = !!userIdFromCookie;

  return isUserLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

const auth = getAuth();
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('La persistance de la session est configurée avec succès.');
  })
  .catch((error) => {
    console.error('Erreur lors de la configuration de la persistance de la session :', error);
  });

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/app/*" element={<PrivateRoute />}>
          <Route path="home" element={<MainApp />} />
          <Route path="folders" element={<Folders />} />
          <Route path="universities" element={<Universities />} />
          <Route path="profile" element={<Profile />} />
          <Route path="folder/:folderId" element={<Folder />} />
          <Route path="home/:folderId/:documentId" element={<Document />} />
          <Route path="home/:organisationId" element={<University />} />
          <Route path="home/:organisationId/folder/:folderId" element={<FolderUniversity />} />
          <Route path="home/:organisationId/folder/:folderId/document/:documentId" element={<Document />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;