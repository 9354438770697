import React, { useEffect, useState } from 'react';
import '../styles/navbar.css';
import FlagIcon from '../hooks/FlagIcon.js';
import 'flag-icons/css/flag-icons.min.css';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [t, i18n] = useTranslation("global");

  const languages = [
    { code: 'gb', name: 'English' },
    { code: 'fr', name: 'French' },
    { code: 'es', name: 'Spanish' },
    { code: 'de', name: 'Deutsch' },
    { code: 'pl', name: 'Poland' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState({ code: 'gb', name: 'English' });

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      const foundLanguage = languages.find(lang => lang.code === selectedLanguage);
      if (foundLanguage) {
        setSelectedLanguage(foundLanguage);
      }
    }
  }, [i18n, languages]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language.code);
    localStorage.setItem('selectedLanguage', language.code);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      const target = event.target.closest('button');
      if (target && target.id === 'language-selector') {
        return;
      }
      setIsOpen(false);
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  return (
    <nav>
      <div className="navbar">
        <div className="flex justify-between h-16 px-10 shadow items-center">
          <div className="flex items-center space-x-8">
            <h1 className="text-xl lg:text-2xl font-bold cursor-pointer">Kadex.</h1>
          </div>
          <div className="flex space-x-4 items-center">
            <div className="relative-selector-lang">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-2 bg-gray-200 px-3 py-2 rounded-md"
                id="language-selector"
              >
                {selectedLanguage && (
                  <FlagIcon countryCode={selectedLanguage.code} />
                )}
              </button>
              {isOpen && (
                <div className="absolute mt-2 w-40 bg-white rounded-md shadow-lg" id='ul-lang-selector-li'>
                  <ul>
                    {languages.map((language) => (
                      <li key={language.code} className="py-2 px-3 hover:bg-gray-100 cursor-pointer" onClick={() => handleLanguageChange(language)}>
                        <FlagIcon countryCode={language.code} />
                        <span className="ml-2">{language.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <a href="/login" className="text-gray-8000 text-sm">Log In</a>
            <a href="/signUp" className="bg-indigo-600 px-4 py-2 rounded text-white hover:bg-indigo-500 text-sm">Sign Up</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;