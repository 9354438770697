// createdoc.js
import { ref, set, push, get, DataSnapshot, remove, update } from 'firebase/database';
import { FIREBASE_DB } from '../../Firebase/firebaseConfig.tsx';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ref as dbRef, set as dbSet } from 'firebase/database';
import Cookies from 'js-cookie';

interface Document {
    id: string;
    title: string;
    creationDate: string;
    lastModifiedDate: string;
    owner: string;
    text: string;
  }
  
  interface Folder {
    id: string;
    folderName: string;
    documents?: Document[];
    creationDate: string;
    owner: string;
  }

export const createFolder = async (folderName) => {
    const userIdFromCookie = Cookies.get('userId');
    try {
        const now = new Date();
        const folderData = {
        folderName: folderName,
        id: generateRandomId(), 
        creationDate: now.toISOString(),
        owner: userIdFromCookie,
        };

        const folderRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folderData.id}`);
        
        await set(folderRef, folderData);

        const event = new CustomEvent('folderCreated', { detail: folderData });
        window.dispatchEvent(event);

        return folderData.id;
    } catch (error) {
        console.error('Erreur lors de la création du dossier:', error.message);
        throw error; 
    }
};

export const createDocumentInFolder = async (folderId, title) => {
    const userIdFromCookie = Cookies.get('userId');
    try {
        const now = new Date();
        const docData = {
            title: title,
            id: generateRandomId(), 
            creationDate: now.toISOString(),
            lastModifiedDate: now.toISOString(),
            owner: userIdFromCookie, 
            text: '',
        };

        const docRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folderId}/Documents/${docData.id}`);
        
        await set(docRef, docData);

    } catch (error) {
        console.error('Erreur lors de la création du document:', error.message);
        throw error; 
    }
};

export const getFolders = async (): Promise<Folder[]> => {
    const userIdFromCookie = Cookies.get('userId');
    const foldersRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder`);
  
    try {
      const snapshot: DataSnapshot = await get(foldersRef);
  
      if (snapshot.exists()) {
        const foldersData: Record<string, Folder> = snapshot.val();
        const foldersArray: Folder[] = Object.values(foldersData);
  
        const foldersWithDocuments = await Promise.all(
          foldersArray.map(async (folder: Folder) => {
            const documentsRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folder.id}/Documents`);
            const documentsSnapshot: DataSnapshot = await get(documentsRef);
  
            if (documentsSnapshot.exists()) {
              const documentsData: Record<string, Document> = documentsSnapshot.val();
              const documentsArray: Document[] = Object.values(documentsData);
              return { ...folder, documents: documentsArray };
            } else {
              return folder;
            }
          })
        );
  
        return foldersWithDocuments;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des dossiers:', error.message);
      throw error;
    }
};

export const deleteDocumentInFolder = async (folderId, documentId) => {
    const userIdFromCookie = Cookies.get('userId');
    try {
        const docRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folderId}/Documents/${documentId}`);
        await remove(docRef);

    } catch (error) {
        console.error('Error deleting document:', error.message);
        throw error;
    }
};

export const updateDocumentTitle = async (folderId, documentId, newTitle) => {
  const userIdFromCookie = Cookies.get('userId');
  try {
      const docRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folderId}/Documents/${documentId}`);
      await update(docRef, { title: newTitle, lastModifiedDate: new Date().toISOString() });

  } catch (error) {
      console.error('Error updating document title:', error.message);
      throw error;
  }
};

export const deleteFolder = async (folderId) => {
  const userIdFromCookie = Cookies.get('userId');
  try {
      const folderRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folderId}`);
      await remove(folderRef);

  } catch (error) {
      console.error('Error deleting folder:', error.message);
      throw error;
  }
};

export const getDocumentsInFolder = async (folderId) => {
  const userIdFromCookie = Cookies.get('userId');
  const documentsRef = ref(FIREBASE_DB, `/Folders/${userIdFromCookie}/folder/${folderId}/Documents`);

  try {
    const snapshot: DataSnapshot = await get(documentsRef);

    if (snapshot.exists()) {
      const documentsData: Record<string, Document> = snapshot.val();
      const documentsArray: Document[] = Object.values(documentsData);

      return documentsArray;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des documents du dossier:', error.message);
    throw error;
  }
};

export const getFolderTitle = async (folderId) => {
  const userId = Cookies.get('userId');
  const folderRef = ref(FIREBASE_DB, `/Folders/${userId}/folder/${folderId}`);

  try {
    const snapshot = await get(folderRef);

    if (snapshot.exists()) {
      const folderData = snapshot.val();
      return folderData.folderName;
    } else {
      console.error('Dossier non trouvé');
      return '';
    }
  } catch (error) {
    console.error('Erreur lors de la récupération du titre du dossier:', error.message);
    throw error;
  }
};

export const getFolderTitleForUniversity = async (organisationId, folderId) => {
  const folderRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}`);

  try {
    const snapshot = await get(folderRef);

    if (snapshot.exists()) {
      const folderData = snapshot.val();
      return folderData.folderName; 
    } else {
      console.error('Dossier non trouvé dans UniversityFolders');
      return '';
    }
  } catch (error) {
    console.error('Erreur lors de la récupération du titre du dossier dans UniversityFolders:', error.message);
    throw error;
  }
};

export const getDocumentsInFolderForUniversity = async (organisationId, folderId) => {
  const documentsRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}/documents`);

  try {
    const snapshot: DataSnapshot = await get(documentsRef);

    if (snapshot.exists()) {
      const documentsData: Record<string, Document> = snapshot.val();
      const documentsArray: Document[] = Object.values(documentsData);

      return documentsArray;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des documents du dossier dans UniversityFolders:', error.message);
    throw error;
  }
};

const generateRandomId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const idLength = 15;
  let result = '';
  for (let i = 0; i < idLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const updateFolderName = async (userId, folderId, newFolderName) => {
  try {
    const folderRef = ref(FIREBASE_DB, `/Folders/${userId}/folder/${folderId}`);
    await update(folderRef, { folderName: newFolderName });
  } catch (error) {
    throw new Error('Failed to update folder name in Firebase: ' + error.message);
  }
};

const cleanFileName = (fileName) => {
  if (fileName) {
    return fileName.replace(/[^\w\s]/gi, '');
  } else {
    return '';
  }
};

const FIREBASE_STORAGE = getStorage();

export const uploadPdfDocument = async (userId, folderId, pdfFile) => {
  try {
    const cleanPdfFileName = cleanFileName(pdfFile);
    
    const storageReference = storageRef(FIREBASE_STORAGE, `Folders/${userId}/${folderId}/${cleanPdfFileName}`);
    
    const metadata = {
      contentType: 'application/pdf'
    };
    
    await uploadBytes(storageReference, pdfFile, metadata);

    const downloadURL = await getDownloadURL(storageReference);
    
    const documentReference = dbRef(FIREBASE_DB, `Folders/${userId}/${folderId}/${cleanPdfFileName}`);
    await dbSet(documentReference, {
      fileName: cleanPdfFileName,
      downloadURL: downloadURL,
    });

    return { success: true, message: 'Fichier PDF importé avec succès.' };
  } catch (error) {
    console.error('Erreur lors de l\'importation du document PDF:', error.message);
    throw error;
  }
};
