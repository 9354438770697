import React, { useState } from 'react';
import Navbar from './Navbar.tsx';
import '../styles/home.css';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import FrontImage from '../assets/11654.png';
// @ts-ignore
import { ReactComponent as InstaSvg } from '../assets/instagram.svg';
// @ts-ignore
import { ReactComponent as TiktokSvg } from '../assets/tiktok.svg';
// @ts-ignore
import { ReactComponent as CopyrightSvg } from '../assets/copyright.svg';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation("global");
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  const faqData = [
    {
      question: t("faq.maxfilesize"),
      answer: t("faq.maxfilesizeq")
    },
    {
      question: t("faq.changepassword"),
      answer: t("faq.changepasswordq")
    },
    {
      question: t("faq.support"),
      answer: t("faq.supportq")
    },
  ];

  const handleQuestionClick = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };
  
  return (
    <div className='main-page-div'>
      <Navbar />
      <div className='main-section-div'>
        <div className='presentation-div'>
          <div className='container'>
            <div className='main-message-div'>
              <h1 className='main-message-title'>{t("mainPage.mainMessage")}</h1>
              <p className='sub-main-message-title'>{t("mainPage.subMainMessage")}</p>
              <Link to="/app/home/"><button className='tryitfor-free'>{t("mainPage.for-free")}</button></Link>
            </div>
            <div>
              <img src={FrontImage} alt="Front image" id="Svg" />
            </div>
          </div>
        </div>
      </div>
      <div className='services-section-div'>
        <div>
          <div>
            <h1 className='services-h1-main'>{t("mainPage.access")}<span className='span-color'>{t("mainPage.free")}</span>{t("mainPage.access-end")}</h1>
          </div>
          <div className='services-container-main'>
            <div className='services-container'>
              <h1>{t("mainPage.services-note")}</h1>
              <p>{t("mainPage.services-note-p")}</p>
              <Link to="/login"><p className='link-p-home-tryit'>{t("mainPage.tryIt")}</p></Link>
            </div>
            <div className='services-container'>
              <h1>{t("mainPage.documentSharing")}</h1>
              <p>{t("mainPage.documentSharing-p")}</p>
              <Link to="/login"><p className='link-p-home-tryit'>{t("mainPage.tryIt")}</p></Link>
            </div>
            <div className='services-container'>
              <h1>{t("mainPage.revisionsessions")}</h1>
              <p>{t("mainPage.revisionssessions-p")}</p>
              <Link to="/login"><p className='link-p-home-tryit'>{t("mainPage.tryIt")}</p></Link>
            </div>
            <div className='services-container'>
              <h1>{t("mainPage.calendar")}</h1>
              <p>{t("mainPage.calendar-p")}</p>
              <Link to="/login"><p className='link-p-home-tryit'>{t("mainPage.tryIt")}</p></Link>
            </div>
            <div className='services-container'>
              <h1>{t("mainPage.iaassistant")}</h1>
              <p>{t("mainPage.iaassistant-p")}</p>
              <Link to="/login"><p className='link-p-home-tryit'>{t("mainPage.tryIt")}</p></Link>
            </div>
          </div>
        </div>
      </div>
      <div className='faq-main-div'>
        <div>
          <h2 className='faq-name-h1'>FAQ</h2>
          <div className='faq-questions'>
            <div>
            {faqData.map((item, index) => (
              <div className='faq-question-second'>
                <div key={index} className='faq-question'>
                  <div
                    className='faq-question-header'
                    onClick={() => handleQuestionClick(index)}
                  >
                    <h3>{item.question}</h3>
                  </div>
                  {openQuestionIndex === index && (
                    <div className='faq-answer'>
                      <p>{item.answer}</p>
                    </div>
                  )}
                </div>
                <div>
                  <span>+</span>
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer'>
        <div className='bottom-footer-div'>
          <div className='bottom-footer-icons-div'>
            <a href="https://www.instagram.com/kadex.fr?igsh=MzRlODBiNWFlZA==">
                <InstaSvg alt="Instagram Svg" />
            </a>
            <a href="https://www.tiktok.com/@kadex.fr">
                <TiktokSvg alt="Tiktok Svg" />
            </a>
          </div>
          <div className='privacy-div'>
            <CopyrightSvg alt="Copyright" />
            <h1>2024 Kadex | Privacy</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;