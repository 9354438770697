import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { getAuth, deleteUser, signOut } from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  const login = (userData) => {
    setUser(userData);
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      Cookies.remove('userId');
      Cookies.remove('email');
    } catch (error) {
      console.error('Error during logout:', error.message);
    }
  };

  const deleteAccount = async () => {
    try {
      await deleteUser(auth.currentUser);
      setUser(null);
      Cookies.remove('userId');
      Cookies.remove('email');
    } catch (error) {
      console.error('Error during account deletion:', error.message);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, deleteAccount }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};