import React, { useEffect, useState } from 'react'
import '../../styles/document.css';
import { useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; 
import TextEditorQuill from '../TextEditorQuill.tsx';
import DarkMode from '../../themes/DarkMode.tsx';
// @ts-ignore
import { ReactComponent as LeftArrow } from '../../assets/leftarrow.svg';
import {
  getDocumentsInFolder,
  getFolderTitle,
  getFolderTitleForUniversity as getFolderTitleForUni, // Rename the imported function
  getDocumentsInFolderForUniversity as getDocsInFolderForUni, // Rename the imported function
} from './createdoc.tsx';

interface Document {
  id: string;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
  owner: string;
  text: string;
}

const Document = () => {
  const { documentId, folderId, organisationId } = useParams();
  const [documentTitle, setDocumentTitle] = useState('');

  useEffect(() => {
    const fetchDocumentTitle = async () => {
      try {
        let folderTitle = '';
        let documentsInFolder: Document[] = [];

        if (organisationId && folderId) {
          folderTitle = await getFolderTitleForUni(organisationId, folderId);
          documentsInFolder = await getDocsInFolderForUni(organisationId, folderId);
        } else if (folderId) {
          folderTitle = await getFolderTitle(folderId);
          documentsInFolder = await getDocumentsInFolder(folderId);
        }

        const document = documentsInFolder.find(doc => doc.id === documentId);
        if (document) {
          setDocumentTitle(`${folderTitle} - ${document.title}`);
        } else {
          console.error('Document not found');
        }
      } catch (error) {
        console.error('Error fetching document title:', error.message);
      }
    };

    fetchDocumentTitle();
  }, [documentId, folderId, organisationId]);
    
    //TextEditorWrapper allows edit text of the document
    const TextEditorWrapper = ({ folderId, documentId }) => {
      return (
        <div>
          <TextEditorQuill folderId={folderId} documentId={documentId} organisationId={organisationId} />
        </div>
      );
    };

    const handleItemClick = (e, folder) => {
      window.history.back();
    };

    return (
      <div className='mainAppMain'>
        <DarkMode />
        <div className='mainDivAllApp'>
          <div className='appDocument'>
            <div className='arrowGoBack'>
            <div className='left-arrow'>
              <LeftArrow alt="LeftArrow Icon" onClick={(e) => handleItemClick(e, folderId)}/>
            </div>
            <h1>{documentTitle}</h1>
            <div></div>
            </div>
            <div>
                <TextEditorWrapper folderId={folderId} documentId={documentId}/>
            </div>
          </div>
        </div>
      </div>
    )
}
export default Document;

