import React, { useState, useEffect } from 'react';
import MainAppNavBar from '../MainAppNavBar.tsx';
import 'react-quill/dist/quill.snow.css';
import '../../styles/profile.css';
// @ts-ignore
import { ReactComponent as SettingsSvg } from '../../assets/settings.svg';
import Cookies from 'js-cookie';
import { FIREBASE_DB } from '../../Firebase/firebaseConfig.tsx';
import { DatabaseReference, get, getDatabase, onValue, ref, update } from 'firebase/database';
import ProfileModal from './ProfileModal.tsx';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const userIdFromCookie = Cookies.get('userId');
  const [t, i18n] = useTranslation("global");
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    status: '',
  });
  const [universities, setUniversities] = useState<string[]>([]);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (userIdFromCookie) {
          const userRef = ref(FIREBASE_DB, `Users/${userIdFromCookie}`);
          onValue(userRef, (snapshot) => {
            const user = snapshot.val();
            if (user) {
              setUserData({
                username: user.username,
                email: user.email,
                status: user.status,
              });
  
              const subscribedUniversities = Object.entries(user.followOrga || {})
                .filter(([university, subscribed]) => subscribed)
                .map(([university]) => university) as string[];
  
                const universitiesDataPromises = subscribedUniversities.map((universityId) => {
                  const universityRef = ref(FIREBASE_DB, `Universities/${universityId}`);
                  return get(universityRef).then((universitySnapshot) => {
                    const universityData = universitySnapshot.val();
                    return universityData?.name || '';
                  });
                });
    
                Promise.all(universitiesDataPromises).then((universitiesNames) => {
                  setUniversities(universitiesNames);
                });
              }
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };
  
    fetchUserData();
  }, [userIdFromCookie]);

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setUserData((prevData) => ({
      ...prevData,
      username: newName,
    }));

    try {
      const userRef = ref(FIREBASE_DB, `Users/${userIdFromCookie}`);
      update(userRef, {
        username: newName,
      });
    } catch (error) {
      console.error('Error updating user name:', error.message);
    }
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setUserData((prevData) => ({
      ...prevData,
      status: newStatus,
    }));

    try {
      const userRef = ref(FIREBASE_DB, `Users/${userIdFromCookie}`);
      update(userRef, {
        status: newStatus,
      });
    } catch (error) {
      console.error('Error updating user status:', error.message);
    }
  };

  const handleItemClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className='mainAppMain'>
      <div className='mainDivAllApp'>
        <div className='mainDivApp'>
          <MainAppNavBar />
        </div>
        <div className='appDocumentProfile'>
          <div className='settingsDiv'>
            <div></div>
            <SettingsSvg alt='SettingsSvg Icon' id='svgSetting' onClick={() => handleItemClick()}/>
          </div>
          <div className='profileInformations'>
            <div className='profileInformationsDiv'>
                <div>
                    <h1>{t("profile.name")}</h1>
                    <input
                        type='text'
                        value={userData.username}
                        onChange={handleNameChange}
                        className='inputInformations'
                    />
                </div>
                <div className='marginDivInformations'>
                    <h1>{t("profile.email")}</h1>
                    <input
                        type='text'
                        value={userData.email}
                        disabled={true}
                        className='inputInformationsEmail'
                    />
                </div>
                <div className='marginDivInformations'>
                    <h1>{t("profile.status")}</h1>
                    <select
                        value={userData.status}
                        onChange={handleStatusChange}
                        className='inputInformations'
                    >
                        <option value='student'>{t("profile.student")}</option>
                        <option value='professional'>{t("profile.professional")}</option>
                        <option value='teacher'>{t("profile.teacher")}</option>
                    </select>
                </div>
                <div className='marginDivInformations'>
                    <h1>{universities.length === 1 ? t("profile.organisation") : t("profile.organisations")}</h1>
                    {universities.length > 0 ? (
                        <p>{universities.join(' - ')}</p>
                    ) : (
                        <p>-</p>
                    )}
                </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <ProfileModal onClose={handleCloseModal} />}
    </div>
  );
};

export default Profile;