import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

interface NoteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (content: string) => Promise<void>;
    initialNoteContent?: string;
}

const NoteModal: React.FC<NoteModalProps> = ({ isOpen, onClose, onSave, initialNoteContent }) => {
    const [t, i18n] = useTranslation("global");
    const [noteContent, setNoteContent] = useState(initialNoteContent || '');

    useEffect(() => {
        const selectedLanguage = localStorage.getItem('selectedLanguage');
        if (selectedLanguage) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, []);

    const handleSave = () => {
        onSave(noteContent); 
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Create/Edit Note Modal"
            style={{
                content: {
                    width: '500px',
                    height: '300px',
                    margin: 'auto',
                    backgroundColor: 'var(--main-background-document)',
                    border: '0px',
                    borderRadius: '10px',
                    color: 'var(--textColor)',
                    padding: '20px',
                    maxWidth: '80%',
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
            }}
        >
            <div className='modalMainDiv'>
                <h2 className='creationOrgaUnih1'>{t("home.createMyNote")}</h2>
                <textarea
                    value={noteContent}
                    onChange={(e) => setNoteContent(e.target.value)}
                    style={{ width: '100%', height: '150px', marginTop: '10px', backgroundColor: 'var(--main-background-document)', border: '1px solid var(--border-color)', padding: '5px' }}
                />
                <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    <button onClick={handleSave}>{t("home.save")}</button>
                    <button onClick={onClose} style={{ marginLeft: '10px' }}>{t("home.cancel")}</button>
                </div>
            </div>
        </Modal>
    );
};

export default NoteModal;