import React, { useState, useEffect } from 'react';

const DarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem("selectedTheme");

    if (storedTheme) {
      if (storedTheme === "dark") {
        setDarkMode();
      } else {
        setLightMode();
      }
    } else {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (prefersDarkMode) {
        setDarkMode();
      } else {
        setLightMode();
      }
    }
  }, []);

  const setDarkMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'dark');
    localStorage.setItem("selectedTheme", "dark");
    setIsDarkMode(true);
  };

  const setLightMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'light');
    localStorage.setItem("selectedTheme", "light");
    setIsDarkMode(false);
  };

  const handleThemeChange = (selectedTheme) => {
    if (selectedTheme === 'dark') {
      setDarkMode();
    } else {
      setLightMode();
    }
  };

  return (
    <div>
      <label>
        <select className="selectionMenuProfile" value={isDarkMode ? 'dark' : 'light'} onChange={(e) => handleThemeChange(e.target.value)}>
          <option value="dark">Dark Mode</option>
          <option value="light">Light Mode</option>
        </select>
      </label>
    </div>
  );
};

export default DarkMode;