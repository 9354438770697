import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, get, push, set, remove, update, child, DatabaseReference, DataSnapshot } from 'firebase/database';
import MainAppNavBar from '../../MainAppNavBar.tsx';
import '../../../styles/folderuniversity.css';
import Cookies from 'js-cookie';
import CreateDocumentModal from './CreateDocumentModal.tsx';
import DeleteDocumentModal from './DeleteDocumentModal.tsx';

// @ts-ignore
import { ReactComponent as DotsSvg } from '../../../assets/dots.svg';
// @ts-ignore
import { ReactComponent as LeftArrow } from '../../../assets/leftarrow.svg';
// @ts-ignore
import { ReactComponent as DocumentText } from '../../../assets/documentText.svg';
// @ts-ignore
import { ReactComponent as Notebook } from '../../../assets/notebook.svg';
// @ts-ignore
import { ReactComponent as DownloadSvg } from '../../../assets/download.svg';
// @ts-ignore
import { ReactComponent as DeleteSvg } from '../../../assets/deletePdf.svg';
// @ts-ignore
import { ReactComponent as AddDocumentSvg } from '../../../assets/add-note.svg';

import { FIREBASE_DB, FIREBASE_STORAGE } from '../../../Firebase/firebaseConfig.tsx';
import { useTranslation } from 'react-i18next';
import { FirebaseStorage, deleteObject, getDownloadURL, getStorage, listAll, uploadBytesResumable } from 'firebase/storage';
import { ref as storageRef, StorageReference } from 'firebase/storage';
import { getDocument } from 'pdfjs-dist';

interface Document {
  id: string;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
  owner: string;
  text: string;
}

interface DeleteDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteDocument: () => Promise<void>;
  onUpdateDocumentName: (newDocumentName: string) => Promise<void>;
  currentDocumentName: string;
}

const FolderUniversity = () => {
  const [t, i18n] = useTranslation("global");
  const userIdFromCookie = Cookies.get('userId');
  const { organisationId, folderId } = useParams();
  const [folderName, setFolderName] = useState<string | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateDocumentModalOpen, setIsCreateDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [pdfUrls, setPdfUrls] = useState<string[]>([]);
  const [selectedPdfForDelete, setSelectedPdfForDelete] = useState(null);
  
  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
    fetchFolderDetails();
  }, [organisationId, folderId]);

  const handleCreateDocumentClick = () => {
    setIsCreateDocumentModalOpen(true);
  };

  const fetchFolderDetails = async () => {
    try {
      if (organisationId && folderId) {
        const folderRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}`);
        const snapshot = await get(folderRef);

        if (snapshot.exists()) {
          const folderData = snapshot.val();
          setFolderName(folderData.folderName);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des détails du dossier:', error.message);
    }
  };

  const handleCreateDocument = async (title: string, text: string) => {
    try {
      if (!organisationId || !folderId) {
        return;
      }

      const documentsRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}/documents`);
      const newDocumentRef = push(documentsRef);
      const newDocumentId = newDocumentRef.key;

      if (newDocumentId) {
        const newDocument: Document = {
          id: newDocumentId,
          title: title,
          text: text,
          creationDate: new Date().toISOString(),
          lastModifiedDate: new Date().toISOString(),
          owner: userIdFromCookie,
        };

        await set(newDocumentRef, newDocument);
        setDocuments((prevDocuments) => [...prevDocuments, newDocument]);
      }
    } catch (error) {
      console.error('Erreur lors de la création du document:', error.message);
    } finally {
      setIsCreateDocumentModalOpen(false);
    }
  };

  const handleOpenDeleteModal = (document: Document) => {
    setSelectedDocument(document);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteDocument = async () => {
    try {
      if (selectedDocument && organisationId && folderId) {
        const documentRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}/documents/${selectedDocument}`);
        await remove(documentRef);
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.id !== selectedDocument.id)
        );
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du document:', error.message);
    }
  };

  const handleItemClickBack = () => {
    window.history.back();
  };

  const fetchDocuments = async () => {
    try {
      if (organisationId && folderId) {
        const documentsRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}/documents`);
        const snapshot = await get(documentsRef);

        if (snapshot.exists()) {
          const documentsData = snapshot.val();
          const documentsArray = Object.keys(documentsData).map((key) => ({
            id: key,
            ...documentsData[key],
          }));
          setDocuments(documentsArray);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des documents:', error.message);
    }
  };

  const handleItemClick = (e, document) => {
    if (e.target.id === 'svgTools') {
      handleOpenDeleteModal(document);
    } else {
      window.location.href = `/app/home/${organisationId}/folder/${folderId}/document/${document}`;
    }
  };

  const handleUpdateDocumentName = async (documentId: string, newDocumentName: string) => {
    try {
      if (selectedDocument && organisationId && folderId) {
        const documentId = selectedDocument
        const documentRef = ref(FIREBASE_DB, `/UniversityFolders/${organisationId}/${folderId}/documents/${documentId}`);
        await update(documentRef, { title: newDocumentName })

        setDocuments((prevDocuments) => prevDocuments.filter(doc => doc.id !== selectedDocument.id));
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du nom du document:', error.message);
    }
  };


  /*Upload pdf section */

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [pdfDocuments, setPdfDocuments] = useState([]);

  useEffect(() => {
    const fetchPdfUrls = async () => {
        try {
            const userId = Cookies.get('userId');
            const storageFolderRef: StorageReference = storageRef(FIREBASE_STORAGE, `UniversityFolder/${organisationId}/${folderId}`);
            const result = await listAll(storageFolderRef);
            const promises = result.items.map((item) => getDownloadURL(item));
            const urls = await Promise.all(promises);
            setPdfUrls(urls);
        } catch (error) {
            console.error('Erreur lors de la récupération des URL des PDF dans le dossier:', error);
        }
    };

      fetchPdfUrls();
  }, [folderId]);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
      if (selectedFile) {
        const pdfUrl = await uploadPdfToStorage(selectedFile);
      }
  };
  
  const uploadPdfToStorage = async (pdfFile: File) => {
      try {
        const userId = Cookies.get('userId');
        const storageReference: StorageReference = storageRef(FIREBASE_STORAGE, `UniversityFolder/${organisationId}/${folderId}/${pdfFile.name}`);

        const uploadTask = uploadBytesResumable(storageReference, pdfFile);
        const snapshot = await uploadTask;
        const url = await getDownloadURL(snapshot.ref);

        console.log('PDF uploaded successfully. URL:', url);
        await updateFirebaseDatabase(userId, url, pdfFile.name);

        return url;
      } catch (error) {
          console.error('Error uploading PDF:', error);
          return null;
      }
  };

  const updateFirebaseDatabase = async (userId: string, pdfUrl: string, fileName: string) => {
    try {
      const newPdfName = generateRandomId();
        const folderItemRef = ref(FIREBASE_DB, `/UniversityPDFFolders/${organisationId}/${folderId}/${newPdfName}`);

        await set(folderItemRef, {
            owner: userId,
            url: pdfUrl,
            fileName: fileName,
        });

        console.log('Database updated successfully.');
    } catch (error) {
        console.error('Error updating database:', error);
    }
  };

  const handleDeletePdf = async (pdfUrl) => {
    try {
        const fileName = getPdfFileName(pdfUrl);
        const userId = Cookies.get('userId');
        const documentpdfRef: StorageReference = storageRef(FIREBASE_STORAGE, `UniversityFolder/${organisationId}/${folderId}/${fileName}`);

        await deleteObject(documentpdfRef);
        console.log('PDF deleted:', fileName);
        setIsDeleteModalOpen(false);
        setSelectedPdfForDelete(null);
    } catch (error) {
        console.error('Erreur lors de la suppression du PDF:', error.message);
    }
  };

  const getPdfFileName = (pdfUrl) => {
    const parts = pdfUrl.split('?');
    const pathSegments = parts[0].split('%2F');
    const fileName = pathSegments[pathSegments.length - 1];
    return decodeURIComponent(fileName);
  };

  const openPdf = (e, pdfUrl: string) => {
    if (e.target.id === 'svgTools') {
      handleDeletePdf(pdfUrl);
    } else {
      window.open(pdfUrl, '_blank');
    }
  };

  const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 30;
    let result = '';
    for (let i = 0; i < idLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const [pdfDocumentsPDF, setPdfDocumentsPDF] = useState<any[]>([]);

  useEffect(() => {
    const fetchPdfDocuments = async () => {
      try {
          const pdfDocumentsRef: DatabaseReference = ref(FIREBASE_DB, `/UniversityPDFFolders/${organisationId}/${folderId}`);
          const snapshot: DataSnapshot = await get(pdfDocumentsRef);
          const pdfDocumentsList: any[] = [];
          
          snapshot.forEach((childSnapshot) => {
              const childData: any = childSnapshot.val();
              pdfDocumentsList.push(childData);
          });

          setPdfDocumentsPDF(pdfDocumentsList);
      } catch (error) {
          console.error('Error fetching PDF documents:', error);
      }
    };

    fetchPdfDocuments();
  }, [organisationId, folderId]);

  useEffect(() => {
    console.log(pdfDocumentsPDF);
  }, [pdfDocumentsPDF]);

  const isUserOwnerOfPdf = (pdfUrl) => {
    const pdf = pdfDocumentsPDF.find((pdf) => pdf.url === pdfUrl);
    return pdf && pdf.owner === userIdFromCookie;
  };
  /*End section pdf */

  return (
    <div className='mainAppMain'>
      <div className='mainDivAllApp'>
        <div className='mainDivApp'>
          <MainAppNavBar />
        </div>
        <div className='appDocumentFolders'>
          <div className='titleDiv'>
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <LeftArrow alt="LeftArrow Icon" style={{ marginTop: '10px', cursor: 'pointer' }} onClick={handleItemClickBack} />
              <h1>{folderName || 'Loading...'}</h1>
            </div>
            <div className='right-folder-informations'>
              <div onClick={handleDivClick}>
                <div
                  className="custom-file-upload"
                  contentEditable={true}
                  style={{ cursor: 'pointer' }}
                >
                  <DownloadSvg alt="Import PDF" />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept=".pdf"
                  onChange={handleFileChange}
                />
              </div>
              <div className='div-add-folder-uni'  onClick={() => handleCreateDocumentClick()}>
                <AddDocumentSvg alt="Add Folder" />
                <button className='buttonCreateFolder'>{t("folderUniversity.createDocument")}</button>
              </div>
            </div>
          </div>
          <div className='fetchFoldersDivUniversity'>
            {documents.length === 0 ? (
              <div className='noFoldersDiv'>
                <div className='noFolderDivSvg'>
                  <Notebook alt="Notebook Icon" id="svgBusy" />
                </div>
                <button className='noFolderButton' onClick={handleCreateDocumentClick}>{t("folderUniversity.createfirstDoc")}</button>
              </div>
            ) : (
              documents.map((document) => (
                <div key={document.id} className='documentItemFolderUniversity' onClick={(e) => handleItemClick(e, document.id)}>
                  <div className='documentInformationsUniversityFolder'>
                    <div>
                        <DocumentText alt="Document Type"/>
                    </div>
                    <div className='documentTools'>
                      <div className='documentInformations'>
                        <h3>{document.title}</h3>
                      </div>
                      {document.owner === userIdFromCookie && (
                        <div onClick={() => handleOpenDeleteModal(document)}>
                          <DotsSvg alt="DotsSvg Icon" id="svgTools" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
            {pdfUrls.map((pdfUrl, index) => (
              <div className='documentItemFolderUniversity'>
                  <div key={index} className='documentInformationsUniversityFolder' onClick={(e) => openPdf(e, pdfUrl)}>
                    <div>
                      <DocumentText alt="Document Type"/>
                    </div>
                    <div className='documentTools'>
                      <div className='documentInformations'>
                        <p>{getPdfFileName(pdfUrl).substring(0, 15)}</p>
                      </div>
                      {userIdFromCookie && isUserOwnerOfPdf(pdfUrl) && (
                          <div onClick={() => handleDeletePdf(pdfUrl)}>
                              <DeleteSvg alt="DotsSvg Icon" id="svgTools" />
                          </div>
                      )}
                    </div>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DeleteDocumentModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDeleteDocument={handleDeleteDocument}
          onUpdateDocumentName={handleUpdateDocumentName} 
          currentDocumentName={selectedDocument?.title || ''}
          documentId={selectedDocument?.id || ''}
        />
      <CreateDocumentModal
        isOpen={isCreateDocumentModalOpen}
        onclose={() => setIsCreateDocumentModalOpen(false)}
        onCreateDocument={handleCreateDocument}
      />
    </div>
  );
};

export default FolderUniversity;