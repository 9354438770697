import React, { useEffect, useState } from 'react';
import MainAppNavBar from './MainAppNavBar.tsx';
import '../styles/mainapp.css';
import 'react-quill/dist/quill.snow.css'; 
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { get, ref, set } from 'firebase/database';
import { FIREBASE_DB } from '../Firebase/firebaseConfig.tsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import NoteModal from './NoteModal.tsx';
import dayjs from 'dayjs';
import { TimeClock } from '@mui/x-date-pickers';

// @ts-ignore
import { ReactComponent as AddSvg } from '../assets/add.svg';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const MainApp = () => {
    const [t, i18n] = useTranslation("global");
    const userIdFromCookie = Cookies.get('userId');
    const usermail = Cookies.get('email');
    const [userData, setUserData] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [notesData, setNotesData] = useState<string []>([]);
    const [studyDuration, setStudyDuration] = useState(0);
    const [pauseDuration, setPauseDuration] = useState(0);
    const [active, setActive] = useState(false);
    const [currentSession, setCurrentSession] = useState('study');
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log('Utilisateur connecté:', user.uid);
          } else {
            console.log('Utilisateur non connecté.');
          }
        });
      
        return () => unsubscribe();
      }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userIdFromCookie) {
                    const userRef = ref(FIREBASE_DB, `/Users/${userIdFromCookie}`);
                    const snapshot = await get(userRef);

                    if (snapshot.exists()) {
                        const userData = snapshot.val();
                        setUserData(userData.username);
                    } else {
                        console.error('Utilisateur non trouvé');
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'username de l\'utilisateur:', error.message);
            }
        };

        fetchData();
    }, [userIdFromCookie]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (remainingTime > 0) {
                setRemainingTime(prevTime => prevTime - 1);
            } else {
                clearInterval(interval);
                if (currentSession === 'study') {
                    setCurrentSession('pause');
                    setRemainingTime(pauseDuration * 60);
                } else {
                    setCurrentSession('study');
                    setRemainingTime(studyDuration * 60); 
                }
            }
        }, 1000);
    
        return () => clearInterval(interval);
    }, [remainingTime, currentSession, studyDuration, pauseDuration]);

    const handleDateChange = async (newDate: Date) => {
        const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
        const noteRef = ref(FIREBASE_DB, `/Calendar/${userIdFromCookie}/${formattedDate}`);
        setSelectedDate(formattedDate);
        
        get(noteRef).then((snapshot) => {
            if (snapshot.exists()) {
                const notesData: string[] = snapshot.val(); 
                setNotesData(notesData);
            } else {
                setNotesData([]);
            }
        }).catch((error) => {
            console.error('Erreur lors de la récupération des notes:', error.message);
        });
    };

    const saveNote = async (textContent: string) => {
        const date = selectedDate;
        try {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            const noteRef = ref(FIREBASE_DB, `/Calendar/${userIdFromCookie}/${formattedDate}`);
            
            const snapshot = await get(noteRef);
            let existingNotes: string[] = [];
    
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (Array.isArray(data)) {
                    existingNotes = data;
                } else {
                    console.error('Les données de notes existantes ne sont pas un tableau.');
                    return;
                }
            }
    
            existingNotes.push(textContent);
            await set(noteRef, existingNotes);
            setNotesData(existingNotes);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement de la note:', error.message);
        }
    };

    const createNewNote = () => {
        setNoteContent('');
        setIsModalOpen(true);
    };

    const handleStudyDurationChange = (selectedOption) => {
        const selectedMinutes = dayjs(selectedOption).minute();
        setStudyDuration(selectedMinutes);
    };

    const handlePauseDurationChange = (selectedOption) => {
        const selectedMinutes = dayjs(selectedOption).minute();
        setPauseDuration(selectedMinutes);
    };

    const startSession = () => {
        setCurrentSession('study');
        setActive(true);
        setRemainingTime(studyDuration * 60);
    };

    const stopSession = () => {
        setCurrentSession('');
        setRemainingTime(0);
    };

    return (
        <div className='mainAppMain'>
            <div className='mainDivAllApp'>
                <div className='mainDivApp'>
                    <MainAppNavBar />
                </div>
                <div className='appDocument'>
                    <div className='usernameNameHello'>
                        <h1>{t("home.hello")} {userData}!</h1>
                    </div>
                    <div className='calendar-div-notes'>
                        <h1>{t("home.myNotes")}</h1>
                        <div className='calendar-div-div-notes'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar 
                                    sx={{
                                        svg: { color: 'var(--textColor)' },
                                        input: { color: 'var(--textColor)' },
                                        label: { color: 'var(--textColor)' },
                                        button: {color: 'var(--textColor)'},
                                        span: { color: 'var(--textColor)'},
                                        div: { color: 'var(--textColor)'}
                                    }}
                                    className='custom-calendar'
                                    onChange={handleDateChange}
                                />
                            </LocalizationProvider>
                            <NoteModal
                                isOpen={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                                onSave={(content: string) => saveNote(content)}
                                initialNoteContent={noteContent}
                            />
                            <div className='my-notes-main'>
                                <div className='addNote-parent-div'>
                                    <div><h1 style={{color: 'var(--textColor)', fontWeight: 'bold'}}>{selectedDate}</h1></div>
                                    <button onClick={createNewNote} className='buttonCreateNote'><AddSvg alt="Add" /><p style={{marginTop: '3px', color: 'var(--textColor)'}}>Add note</p></button>
                                </div>
                                <div className='my-notes-notes'>
                                {notesData.length > 0 ? (
                                    <div className='my-notes' style={{color: 'white'}}>
                                        {notesData.map((note, index) => (
                                            <div key={index} className='note-item'>
                                                <p>{note}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className='my-notes'>
                                        <p style={{color: 'var(--textColor)'}}>{t("home.noNotes")}</p>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='main-clock-div'>
                        <div className='clock-name-div'>
                            <h1>{t("home.mysession")}</h1>
                        </div>
                        <div className='clocks-informations'>
                            <div className='clocks-informations-second'>
                                <div>
                                    <h1>{t("home.review")}</h1>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeClock views={['minutes']} onChange={handleStudyDurationChange}/>
                                    </LocalizationProvider>
                                </div>
                                <div className='clocks-informations-text'>
                                    <div>
                                        <h1>{t("home.timeleft")} {Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, '0')}</h1>
                                        <button onClick={startSession}>{t("home.start")}</button>
                                        {currentSession === "study" && active === true ? 
                                            (<h1>{t("home.shouldStudy")}</h1>)
                                         : 
                                            (<h1>{t("home.pauseBreak")}</h1>)

                                        }
                                    </div>
                                </div>
                                <div>
                                    <h1>{t("home.break")}</h1>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeClock views={['minutes']} onChange={handlePauseDurationChange}/>
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainApp;
