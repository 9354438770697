import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import '../../styles/modalTools.css';

const ModalTools = ({ isOpen, onClose, onCreateUniversity }) => {
  const [t, i18n] = useTranslation("global");
  const [organizationTitle, setOrganizationTitle] = useState('');
  const [organizationType, setOrganizationType] = useState('school');
  const [organizationVisibility, setOrganizationVisibility] = useState('public');

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setOrganizationTitle('');
      setOrganizationType('school');
      setOrganizationVisibility('public');
    }
  }, [isOpen]);
  
  const handleCreateUniversity = async () => {
    console.log('Creating university...');
    await onCreateUniversity(organizationTitle, organizationType, organizationVisibility);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Supprimer le dossier"
      style={{
        content: {
          width: '300px',
          height: '370px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)',
          maxWidth: '80%'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
      }}
    >
      <div className='modalMainDiv'>
        <div className='mainButtonContainerUni'>
          <div className='buttonContainerModalUni'>
            <h1 className='creationOrgaUnih1'>{t("modalCreateUniversity.organisationCreationTitle")}</h1>
            <div>
              <label>{t("modalCreateUniversity.organisationName")}</label>
              <input type="text" value={organizationTitle} onChange={(e) => setOrganizationTitle(e.target.value)}  className='organisationModalCreateInputs'/>
            </div>
            <div>
              <h1>{t("modalCreateUniversity.organisationType")}</h1>
              <select value={organizationType} onChange={(e) => setOrganizationType(e.target.value)} className='organisationModalCreateInputs'>
                <option value="school">{t("modalCreateUniversity.organisationSchool")}</option>
                <option value="company">{t("modalCreateUniversity.organisationCompany")}</option>
              </select>
            </div>
            <div>
              <h1>{t("modalCreateUniversity.organisationVisibility")}</h1>
              <select value={organizationVisibility} onChange={(e) => setOrganizationVisibility(e.target.value)} className='organisationModalCreateInputs'>
                <option value="public">{t("modalCreateUniversity.organisationPublic")}</option>
                <option value="private">{t("modalCreateUniversity.organisationPrivate")}</option>
              </select>
            </div>

            <button className='organisationModalCreateInputsButton' onClick={handleCreateUniversity}>{t("modalCreateUniversity.create")}</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTools;