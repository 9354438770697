import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail, signOut, fetchSignInMethodsForEmail, getAuth, browserSessionPersistence, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { useAuth } from '../hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FIREBASE_AUTH, FIREBASE_DB } from '../Firebase/firebaseConfig.tsx'
import { Database, child, get, ref, set } from 'firebase/database';
import '../styles/login.css'
import { useTranslation } from 'react-i18next';

// @ts-ignore
import { ReactComponent as Googlesvg } from '../assets/googlesvg.svg';

const Login = () => {
  const [t, i18n] = useTranslation("global");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(navigator.language);

  const { login, user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const userIdFromCookie = Cookies.get('userId');
    if (userIdFromCookie && !user) {
      login({ uid: userIdFromCookie });
      navigate('/app/home');
    }
  }, [user, navigate]);

  const signIn = async () => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(FIREBASE_AUTH, email, password);
      const user = userCredential.user;

      console.log('Connecté avec succès', user);
      Cookies.set('userId', user.uid, { expires: 14 });
      Cookies.set('email', user.email, { expires: 14 });

      const userId = Cookies.get('userId');

      const userRef = ref(FIREBASE_DB, `/Users/${userId}`);
      const userSnapshot = await get(userRef);

      navigate('/app/home');
      login({ email: user.email, uid: user.uid });
    } catch (error) {
      console.error('Erreur de connexion:', error.message);
      if (error.code) {
        switch (error.code) {
          case 'auth/invalid-email':
            setError(t("login.emailadress"));
            break;
          case 'auth/user-not-found':
          case 'auth/wrong-password':
            setError(t("login.passemail"));
            break;
          default:
            setError(t("login.unexpected"));
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    try {
      await sendPasswordResetEmail(FIREBASE_AUTH, email);
      console.log("Email de réinitialisation de mot de passe envoyé!");
    } catch (error) {
      setError('');
    }
  };

  const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const idLength = 50;
    let result = '';
    for (let i = 0; i < idLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const signInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
        const userCredential = await signInWithPopup(auth, provider);
        const user = userCredential.user;

        if (user.email) {
            const userId = user.uid;
            const userRef = ref(FIREBASE_DB, `/Users/${userId}`);
            const userSnapshot = await get(userRef);

            if (userSnapshot.exists()) {
                console.log('User already exists:', user.email);
                Cookies.set('userId', userId, { expires: 14 });
                Cookies.set('email', user.email, { expires: 14 });
                navigate('/app/home');

            } else {
                const username = user.displayName || ''; 
                const fullname = user.displayName || '';
                const status = 'student';
                const language = navigator.language || 'en';

                const userData = {
                    email: user.email,
                    fullname,
                    username,
                    status,
                    userId,
                    createdAt: new Date().toISOString(),
                    accountType: 'default',
                    accountAdmin: false,
                    language,
                };

                await set(ref(FIREBASE_DB, `/Users/${userId}`), userData);

                const folderData = {
                    email: user.email,
                    username,
                    userId,
                    folder: '',
                    status,
                    createdAt: new Date().toISOString(),
                    accountType: 'default',
                    accountAdmin: false,
                    language,
                };

                await set(ref(FIREBASE_DB, `/Folders/${userId}`), folderData);
                
                Cookies.set('userId', userId, { expires: 14 });
                Cookies.set('email', user.email, { expires: 14 });

                navigate('/app/home');
            }
        }
    } catch (error) {
        console.error('Erreur de connexion avec Google:', error.message);
    }
};

  return (
    <div className="h-screen bg-gray-100 flex justify-center">
      <div className="py-6 px-8 h-80 mt-20 bg-white rounded shadow-xl">
        <div className="mb-6">
          <label className="block text-gray-800 font-bold">{t("login.name")}</label>
          <input type="text" name="name" id="name" placeholder={t("login.email")} value={email} onChange={(e) => setEmail(e.target.value)} className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600" />
        </div>

        <div>
          <label className="block text-gray-800 font-bold">{t("login.password")}</label>
          <input type="password" name="email" id="email" placeholder={t("login.ppassword")} value={password} onChange={(e) => setPassword(e.target.value)} className="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600" />
          <a href="#" onClick={resetPassword} className="text-sm font-thin text-gray-800 hover:underline mt-2 inline-block hover:text-indigo-600">{t("login.forgot")}</a>
        </div>
        <button className="cursor-pointer py-2 px-4 block mt-6 bg-indigo-500 text-white font-bold w-full text-center rounded" onClick={signIn}>{t("login.login")}</button>

        {error && <h1 style={{ color: 'red', marginTop: '5px', position: 'absolute'}}>{error}</h1>}
        <div className='other-option-sign-in'>
          <h1>{t("login.otheroption")}</h1>
          
          <button className="google-sign-in" onClick={signInWithGoogle}>
            <div className='sign-in-option'>
              <Googlesvg alt="Google" />Google
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;