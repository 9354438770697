import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf'; // Importez les composants nécessaires depuis react-pdf
import { createDocumentInFolder, deleteDocumentInFolder, getDocumentsInFolder, getFolderTitle, uploadPdfDocument } from '../documents/createdoc.tsx';
import CreateDocumentModal from '../documents/CreateDocumentModal.tsx';
import MainAppNavBar from '../MainAppNavBar.tsx';
import DeleteDocumentModal from './deleteDocumentModal.tsx';
import '../../styles/folderDoc.css';
import PdfViewerModal from './PdfViewerModal.tsx';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytesResumable } from 'firebase/storage';
import { FIREBASE_AUTH, FIREBASE_STORAGE } from '../../Firebase/firebaseConfig.tsx';
// @ts-ignore
import { ReactComponent as DotsSvg } from '../../assets/dots.svg';
// @ts-ignore
import { ReactComponent as LeftArrow } from '../../assets/leftarrow.svg';
// @ts-ignore
import { ReactComponent as Notebook } from '../../assets/notebook.svg';
// @ts-ignore
import { ReactComponent as DownloadSvg } from '../../assets/download.svg';
// @ts-ignore
import { ReactComponent as DeleteSvg } from '../../assets/deletePdf.svg';
// @ts-ignore
import { ReactComponent as AddDocumentSvg } from '../../assets/add-note.svg';

// Configuration de react-pdf pour l'affichage des PDF
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Document {
  id: string;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
  owner: string;
  text: string;
}

const Folder = () => {
  const { folderId } = useParams();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [folderTitle, setFolderTitle] = useState<string>("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [pdfFiles, setPdfFiles] = useState<string[] | undefined>(undefined);
  const [pdfUrls, setPdfUrls] = useState<string[]>([]);
  const [selectedPdfForDelete, setSelectedPdfForDelete] = useState(null);
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const folderDocuments = await getDocumentsInFolder(folderId);
        setDocuments(folderDocuments);
      } catch (error) {
        console.error('Erreur lors de la récupération des documents du dossier:', error.message);
      }
    };

    const fetchFolderTitle = async () => {
      try {
        const title = await getFolderTitle(folderId);
        setFolderTitle(title);
      } catch (error) {
        console.error('Erreur lors de la récupération du titre du dossier:', error.message);
      }
    };

    fetchDocuments();
    fetchFolderTitle();
  }, [folderId]);

  useEffect(() => {
    const fetchPdfUrls = async () => {
      try {
        const userId = Cookies.get('userId');
        const storageRef = ref(FIREBASE_STORAGE, `Folders/${userId}/${folderId}`);
        const result = await listAll(storageRef);
        const promises = result.items.map((item) => getDownloadURL(item));
        const urls = await Promise.all(promises);
        setPdfUrls(urls);
      } catch (error) {
        console.error('Erreur lors de la récupération des URL des PDF dans le dossier:', error);
      }
    };

    fetchPdfUrls();
  }, [folderId]);

  const uploadPdfToStorage = async (pdfFile: File) => {
    try {
      const userId = Cookies.get('userId');
      const storageRef = ref(FIREBASE_STORAGE, `Folders/${userId}/${folderId}/${pdfFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, pdfFile);
  
      const snapshot = await uploadTask;
  
      const url = await getDownloadURL(snapshot.ref);
  
      console.log('PDF uploaded successfully. URL:', url);
  
      return url;
    } catch (error) {
      console.error('Error uploading PDF:', error);
      return null;
    }
  };
  
  const handleImportPdf = async (event) => {
    event.preventDefault();

    const pdfFile = event.target.files[0];

    if (pdfFile) {
      const pdfUrl = await uploadPdfToStorage(pdfFile);
      console.log('URL of uploaded PDF:', pdfUrl);
    }
  };

  const handleCreateDocumentClick = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateDocument = async (title) => {
    try {
      await createDocumentInFolder(folderId, title);
      setIsCreateModalOpen(false);
      const folderDocuments = await getDocumentsInFolder(folderId);
      setDocuments(folderDocuments);
    } catch (error) {
      console.error('Erreur lors de la création du document:', error.message);
    }
  };

  const handleOpenDeleteModal = (document: Document) => {
    setSelectedDocument(document);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteDocument = async () => {
    try {
      const doc = selectedDocument?.id;
      await deleteDocumentInFolder(folderId, doc);
      const folderDocuments = await getDocumentsInFolder(folderId);
      setDocuments(folderDocuments);
    } catch (error) {
      console.error('Erreur lors de la suppression du document:', error.message);
    }
  };

  const handleItemClick = (e, folder, document) => {
    if (e.target.id === 'svgTools') {
      handleOpenDeleteModal(document);
    } else {
      window.location.href = `/app/home/${folder}/${document.id}`;
    }
  };

  const handleItemClickBack = (e, folder) => {
    window.history.back();
  };

  const openPdf = (e, pdfUrl: string) => {
    if (e.target.id === 'svgTools') {
      handleDeletePdf(pdfUrl);
    } else {
      window.open(pdfUrl, '_blank');
    }
  };

  const getPdfFileName = (pdfUrl) => {
    const parts = pdfUrl.split('?');
    const pathSegments = parts[0].split('%2F');
    const fileName = pathSegments[pathSegments.length - 1];
    return decodeURIComponent(fileName);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
      if (selectedFile) {
        const pdfUrl = await uploadPdfToStorage(selectedFile);
      }
  };

  const handleDeletePdf = async (pdfUrl) => {
    try {
      const fileName = getPdfFileName(pdfUrl);
      const userId = Cookies.get('userId');
      const storageRef = ref(FIREBASE_STORAGE, `Folders/${userId}/${folderId}/${fileName}`);
      await deleteObject(storageRef);
      console.log('PDF deleted:', fileName);
      setIsDeleteModalOpen(false);
      setSelectedPdfForDelete(null);
    } catch (error) {
      console.error('Erreur lors de la suppression du PDF:', error.message);
    }
  };

  return (
    <div className='mainAppMain'>
      <div className='mainDivAllApp'>
        <div className='mainDivApp'>
          <MainAppNavBar />
        </div>
        <div className='appDocumentFolders'>
          <div className='titleDiv'>
            <div style={{display: 'flex', marginTop: '5px'}}>
              <LeftArrow alt="LeftArrow Icon" style={{marginTop: '10px', cursor: 'pointer'}} onClick={(e) => handleItemClickBack(e, folderId)}/>
              <h1>{folderTitle}</h1>
            </div>
            <div className='right-folder-informations'>
              <div onClick={handleDivClick}>
                <div
                  className="custom-file-upload"
                  contentEditable={true}
                  style={{ cursor: 'pointer' }}
                >
                  <DownloadSvg alt="Import PDF" />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept=".pdf"
                  onChange={handleFileChange}
                />
              </div>
              <div className='div-add-folder-uni'  onClick={() => handleCreateDocumentClick()}>
                <AddDocumentSvg alt="Add Folder" />
                <button className='buttonCreateFolder'>{t("folderUniversity.createDocument")}</button>
              </div>
            </div>
          </div>
          <div className='documentContainer'>
            {documents.length > 0 ? (
              <div className='fetchDocumentsDiv'>
                {documents.map(document => (
                  <div key={document.id} className='documentItem' onClick={(e) => handleItemClick(e, folderId, document)}>
                    <div className='documentInformations'>
                      <h3>{document.title}</h3>
                      <p>{document.text}</p>
                    </div>
                    <div className='folderToolsFolder'>
                      <div></div>
                      <div onClick={() => handleOpenDeleteModal(document)}>
                        <DotsSvg alt="ProfileSvg Icon" id="svgTools" />
                      </div>
                    </div>
                  </div>
                ))}
                  <div className='fetchDocumentsDiv'>
                    {pdfUrls.map((pdfUrl, index) => (
                      <div key={index} className='documentItem' onClick={(e) => openPdf(e, pdfUrl)}>
                        <div className='documentInformations'>
                          <p>{getPdfFileName(pdfUrl)}</p>
                        </div>
                        <div className='folderToolsFolder'>
                          <div></div>
                          <div onClick={() => handleDeletePdf(pdfUrl)}>
                            <DeleteSvg alt="ProfileSvg Icon" id="svgTools" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
              </div>
            ) : (
              <div className='noFoldersDiv'>
                <div className='noFolderDivSvg'>
                  <Notebook alt="Notebook Icon" id="svgBusy" />
                </div>
                <button className='noFolderButton' onClick={() => handleCreateDocumentClick()}>{t("folderUniversity.createfirstDoc")}</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateDocumentModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onCreateFolder={handleCreateDocument}
      />
      <DeleteDocumentModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDeleteDocument={handleDeleteDocument}
        document={selectedDocument}
        folderId={folderId}
      />
    </div>
  );
};

export default Folder;