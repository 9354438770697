import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { updateDocumentTitle } from '../documents/createdoc.tsx';

interface Document {
  id: string;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
  owner: string;
  text: string;
}

const DeleteDocumentModal = ({ isOpen, onClose, onDeleteDocument, document, folderId }) => {
  const [t, i18n] = useTranslation("global");
  const [newTitle, setNewTitle] = useState(document?.title || '');

  const handleDelete = () => {
    onDeleteDocument();
    onClose();
  };

  const handleUpdateTitle = async () => {
    if (document && folderId) {
      try {
        await updateDocumentTitle(folderId, document.id, newTitle);
        onClose();
      } catch (error) { 
        console.error('Error updating document title:', error);
      }
    }
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Supprimer le document"
      style={{
        content: {
          width: '350px',
          height: '250px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <div className='modalMainDivFolderDelete'>
        <div className='mainButtonContainerFolderDelete'>
          <div>
            <h1 style={{textAlign: 'left'}}>{t("modalDeleteFolder.createdoc")}</h1>
            <input
              type="text"
              placeholder={t("modalDeleteFolder.modifydoc")}
              value={newTitle}
              className='inputs-folder'
              onChange={(e) => setNewTitle(e.target.value)}
            />
          </div>
          <div className='bottom-footer-div-folder-modal'>
            <div className='buttonContainerFolderDelete' style={{textAlign: 'center', marginTop: '20px', fontSize: '20px'}}>
              <button onClick={handleDelete}>{t("modalDeleteFolder.delete")}</button>
            </div>
            <button onClick={handleUpdateTitle} className='save-button-folder'>{t("modalDeleteFolder.save")}</button>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDocumentModal;