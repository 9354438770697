import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const ModalDeleteOrga = ({ isOpen, onClose, onConfirm }) => {
  const [t, i18n] = useTranslation("global");
  
  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete University"
      style={{
        content: {
          width: '300px',
          height: '170px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)',
          maxWidth: '80%'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <div className='modalMainDiv'>
        <div className='mainButtonContainer'>
          <div className='buttonContainer'>
            <h2 className='creationOrgaUnih1'>{t("deleteOrgaModal.confirmDeletep")}</h2>
            <div style={{marginTop: '10px'}}>
              <button onClick={onConfirm}>{t("deleteOrgaModal.delete")}</button>
              <button onClick={onClose} style={{marginLeft: '10px'}}>{t("deleteOrgaModal.cancel")}</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteOrga;