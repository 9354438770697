import React, { useState, useEffect } from 'react';

//Dark mode page for the user to select light or dark mode theme.

const DarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem("selectedTheme");

    if (storedTheme) {
      if (storedTheme === "dark") {
        setDarkMode();
      } else {
        setLightMode();
      }
    } else {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (prefersDarkMode) {
        setDarkMode();
      } else {
        setLightMode();
      }
    }
  }, []);

  const setDarkMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'dark');
    localStorage.setItem("selectedTheme", "dark");
    setIsDarkMode(true);
  };

  const setLightMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'light');
    localStorage.setItem("selectedTheme", "light");
    setIsDarkMode(false);
  };

  const toggleTheme = () => {
    if (isDarkMode) {
      setLightMode();
    } else {
      setDarkMode();
    }
  };

  return (
    <div>
    </div>
  );
};

export default DarkMode;