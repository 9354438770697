import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

interface DeleteFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteFolder: (folderId: string) => Promise<void>;
  onUpdateFolderName: (folderId: string, newFolderName: string) => Promise<void>;
  folderId: string;
  currentFolderName: string;
}

const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({
  isOpen,
  onClose,
  onDeleteFolder,
  onUpdateFolderName,
  folderId,
  currentFolderName,
}) => {
  const [t, i18n] = useTranslation("global");
  const [newFolderName, setNewFolderName] = useState(currentFolderName);

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  const handleDeleteFolder = async () => {
    try {
      await onDeleteFolder(folderId);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la suppression du dossier:', error.message);
    }
  };

  const handleSaveFolderName = async () => {
    try {
      await onUpdateFolderName(folderId, newFolderName.trim());
      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du nom du dossier:', error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete Folder"
      style={{
        content: {
          width: '350px',
          height: '250px',
          margin: 'auto',
          backgroundColor: 'var(--main-background-document)',
          border: '0px',
          borderRadius: '10px',
          color: 'var(--textColor)',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
      }}
    >
      <div className='modalMainDivFolderDelete'>
        <div className='mainButtonContainerFolderDelete'>
          <div>
            <h1 style={{textAlign: 'left'}}>{t("modalDeleteFolder.updatefolder")}</h1>
            <input
              type="text"
              placeholder={t("modalDeleteFolder.modifyFolder")}
              value={newFolderName}
              className='inputs-folder'
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </div>
          <div className='bottom-footer-div-folder-modal'>
            <div className='buttonContainerFolderDelete' style={{textAlign: 'center', marginTop: '20px', fontSize: '20px'}}>
              <button onClick={handleDeleteFolder}>{t("modalDeleteFolder.delete")}</button>
            </div>
            <button onClick={handleSaveFolderName} className='save-button-folder'>{t("modalDeleteFolder.save")}</button>

          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFolderModal;